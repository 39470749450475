import React, { useState, useContext, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AppContext } from "../context";
import { useHistory } from "react-router-dom";
import axios from "../axios";
import "./login.scss";
import { getCookie, setJWT, getDomain, passDataToIframe } from "../utils";
import { encode, convertUTF16 } from "../utils";

const SignIn = () => {
  const reCaptchaRef = useRef(null); // React.createRef<ReCAPTCHA>();
  const [appState, appDispatch] = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [agreed, setAgreed] = useState(true);
  const [popup, setPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("sign-in");
  const [callback, setCallback] = useState(null);
  const [message, setMessage] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const passwordRequirementText =
    "Password must be a minimum of 8 characters, include upper and lowercase letters and at least one number.";

  const history = useHistory();
  let redirectPath = "/";
  if (
    history &&
    history.location &&
    history.location.state &&
    history.location.state.from
  ) {
    redirectPath = history.location.state.from;
  }

  function setRedirectCookie(redirectPath) {
    const hostname = window.location.hostname;
    const port = window.location.port;
    const cookieValue = `//${hostname}:${port}${redirectPath}`;
    const expires = "Fri, 31 Dec 9999 23:59:59 GMT";
    const path = "/";

    // Construct the cookie
    let cookie = `_IELTS69_REDIRECT_=${cookieValue};expires=${expires};path=${path}`;

    // Add domain only if not localhost
    if (hostname !== "localhost") {
      cookie += `;domain=${hostname}`;
    }

    console.log("cookie --->", cookie);
    // Set the cookie
    document.cookie = cookie;
  }

  function readAndClearCookie(cookieName) {
    // Read the cookie
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    const cookieValue = getCookie(cookieName);

    // Clear the cookie
    if (cookieValue) {
      document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
    }

    return cookieValue; // Return the cookie value if it exists
  }

  const getUserInfo = () => {
    let redirectPath = readAndClearCookie("_IELTS69_REDIRECT_");
    redirectPath = redirectPath || "/";
    history.push(redirectPath);
  };

  const resetCaptcha = () => {
    if (reCaptchaRef && reCaptchaRef.current) {
      reCaptchaRef.current.reset();
    }
  };

  const userNotAgreed = () => {
    if (!agreed) {
      alert("You must agree to our Privacy Policy and Terms & Conditions");
      return true;
    } else {
      return false;
    }
  };

  const validateEmail = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validatePassword = () => {
    const re = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return re.test(password) && password.length > 7;
  };

  const validateFields = (doPassword = true, doEmail = true) => {
    let isFieldsValid = true;
    const errors = [];

    if (doEmail && !validateEmail()) {
      errors.push({
        message: "Email is not valid",
        field: "email",
      });
      isFieldsValid = false;
    }

    if (doPassword && !validatePassword()) {
      errors.push({
        message: passwordRequirementText,
        field: "password",
      });
      isFieldsValid = false;
    }

    if (errors.length > 0) setErrorMessages(errors);

    return isFieldsValid;
  };

  const showErrors = () => {
    let errors;

    if (Array.isArray(errorMessages)) {
      errors = errorMessages.map((error) => {
        if (typeof error === "string") {
          return (
            <li className="error" key={error}>
              {error}
            </li>
          );
        }
        if (error.message) {
          return (
            <li className="error" key={error.message}>
              {error.message}
            </li>
          );
        }
        return null;
      });
    } else {
      errors = <li className="error">{errorMessages}</li>;
    }
    return <ul className="messageList">{errors}</ul>;
  };

  const processJWT = (JWT) => {
    //window.ga("send", "event", "Goal", "sign_in_up", `${redirectPath}`, 1);
    setJWT(JWT);
    getUserInfo();
  };

  const handleSignInSubmit = (e) => {
    e.preventDefault();

    if (userNotAgreed()) {
      return false;
    }

    if (!validateFields()) return;

    setCallback(() => () => {
      setErrorMessages([]);
      axios
        .post("/api/auth/sign-in", {
          email,
          password,
          reCaptcha: reCaptchaRef.current?.getValue(),
        })
        .then((response) => {
          const { JWT } = response.data;
          if (JWT) {
            processJWT(JWT);
          }
        })
        .catch((err) => {
          setErrorMessages(err.response.data.message);
        })
        .finally(() => {
          resetCaptcha();
          setCallback(null);
          window.location.reload();
        });
    });
  };

  const handleSignUpSubmit = (e) => {
    e.preventDefault();

    if (userNotAgreed()) {
      return false;
    }

    if (!validateFields()) return;

    setCallback(() => () => {
      setErrorMessages([]);
      axios
        .post("/api/auth/sign-up", {
          email,
          password,
          reCaptcha: reCaptchaRef.current?.getValue(),
        })
        .then((response) => {
          const { JWT } = response.data;
          if (JWT) {
            // window.ga(
            //   "send",
            //   "event",
            //   "Goal",
            //   "sign_in_up",
            //   `/app${redirectPath}`,
            //   1
            // );
            setJWT(JWT);
            getUserInfo();
          }
        })
        .catch((err) => {
          setErrorMessages(err.response.data.message);
        })
        .finally(() => {
          resetCaptcha();
          setCallback(null);
        });
    });
  };

  const handleRecoverEmailSubmit = (e) => {
    e.preventDefault();

    if (!validateFields(false)) return;

    setCallback(() => () => {
      setErrorMessages([]);
      axios
        .post("/api/auth/reset-password", {
          email,
          reCaptcha: reCaptchaRef.current?.getValue(),
        })
        .then((response) => {
          setMessage(response.data.message);
          setStatus("email-sent");
        })
        .catch((err) => {
          setErrorMessages(err.response.data.message);
        })
        .finally(() => {
          resetCaptcha();
          setCallback(null);
        });
    });
  };

  const onCaptchaEntered = (value) => {
    if (value && callback) callback();
    // window.ga("send", "event", "Process_Text", "Ok", "Ok");
  };

  React.useEffect(() => {
    window.btoa = encode;

    if (redirectPath) {
      setRedirectCookie(redirectPath);
    }

    return () => {
      console.log("cleanup");
    };
  }, []);

  return (
    <div className="signIn overlay">
      <style>{`
        body {
        background-color: #eee;
        }
.clearFix{
display: flex;
justify-content: space-between;
        
}

      `}</style>
      <div className="signInPopup">
        {/* <a
          href="#close"
          className="closeBtn"
          onClick={(e) => {
            e.preventDefault();
            closePopup();
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            />
          </svg>
        </a> */}
        <h1>
          {status === "sign-in" && `Log In`}
          {status === "sign-up" && "Sign up for your account"}
          {status === "forgot" && "We'll send a recovery link to"}
          {status === "email-sent" && "Email has been sent"}
          {status === "success" && "You successfully logged in"}
        </h1>
        {showErrors()}
        {status === "email-sent" && (
          <div className="messageList">{message}</div>
        )}
        {(status === "sign-in" ||
          status === "sign-up" ||
          status === "forgot") && (
          <div
            className={
              Array.isArray(errorMessages) &&
              errorMessages.find((el) => el?.field === "email")
                ? "blockWrapper error"
                : "blockWrapper"
            }
          >
            <div className="inputLabel">Email</div>
            <input
              type="email"
              name="email"
              value={email}
              placeholder=""
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        )}
        {(status === "sign-in" || status === "sign-up") && (
          <div
            className={
              Array.isArray(errorMessages) &&
              errorMessages.find((el) => el?.field === "password")
                ? "blockWrapper error"
                : "blockWrapper"
            }
          >
            <div className="inputLabel">Password</div>
            <input
              type="password"
              name="password"
              value={password}
              placeholder=""
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        )}
        {status === "sign-up" && (
          <div className="blockWrapper passwordRequirements">
            {passwordRequirementText}
          </div>
        )}
        {status === "sign-in" && (
          <a href="#sign-in" className="signInBtn" onClick={handleSignInSubmit}>
            Sign In
          </a>
        )}
        {status === "sign-up" && (
          <a href="#sign-up" className="signInBtn" onClick={handleSignUpSubmit}>
            Sign Up
          </a>
        )}
        {status === "forgot" && (
          <a
            href="#forgot"
            className="signInBtn"
            onClick={handleRecoverEmailSubmit}
          >
            Send recovery link
          </a>
        )}
        <div
          style={{
            display: callback ? "block" : "none",
          }}
        >
          <div className="captchaWrapper">
            <ReCAPTCHA
              ref={reCaptchaRef}
              onChange={onCaptchaEntered}
              sitekey="6Lepq-kZAAAAAGKf0lT5KHuUS3sQDYnuc77NZijB"
            />
          </div>
          <div
            onClick={() => {
              setCallback(null);
            }}
            className="captchaOverlay"
            aria-hidden="true"
          />
          <br />
        </div>
        {(status === "sign-in" || status === "sign-up") && (
          <>
            <div className="signInOr">Or</div>

            <a
              href="#google"
              className="socialBtn google"
              onClick={async (e) => {
                e.preventDefault();

                if (userNotAgreed()) {
                  return false;
                }

                const domain = getDomain();
                window.location.href =
                  window.location.hostname === "localhost"
                    ? "http://localhost:3050/api/auth/google6?signInFrom=diplom"
                    : `${domain}/api/auth/google6?signInFrom=diplom`;
              }}
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    d="M3.345 8.17c0-.519.087-1.016.24-1.483L.893 4.631a7.985 7.985 0 0 0-.82 3.54c0 1.271.295 2.471.819 3.536l2.69-2.06a4.727 4.727 0 0 1-.237-1.477"
                    fill="#FBBC05"
                  />
                  <path
                    d="M8.073 3.45c1.127 0 2.145.4 2.945 1.053l2.327-2.324C11.927.944 10.11.182 8.073.182a7.976 7.976 0 0 0-7.18 4.45l2.692 2.055A4.714 4.714 0 0 1 8.073 3.45"
                    fill="#EA4335"
                  />
                  <path
                    d="M8.073 12.89a4.714 4.714 0 0 1-4.488-3.237L.893 11.71a7.976 7.976 0 0 0 7.18 4.45c1.951 0 3.814-.693 5.212-1.991l-2.556-1.976c-.72.454-1.629.699-2.656.699"
                    fill="#34A853"
                  />
                  <path
                    d="M15.71 8.17c0-.472-.074-.98-.183-1.452H8.073v3.086h4.29c-.214 1.053-.798 1.862-1.634 2.388l2.556 1.976c1.469-1.363 2.424-3.394 2.424-5.998"
                    fill="#4285F4"
                  />
                </g>
              </svg>
              Continue with Google
            </a>
            <div className="privacy">
              <label>
                <input
                  type="checkbox"
                  checked={agreed}
                  onChange={() => {
                    setAgreed(!agreed);
                  }}
                />{" "}
                I agree to the {appState.siteName} application's
              </label>
              <br />
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setPopup("privacy");
                }}
                href="#privacy-policy"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setPopup("terms");
                }}
                href="#terms-conditions"
              >
                Terms &amp; Conditions
              </a>
            </div>
          </>
        )}
        {status === "success" && (
          <div className="icon-success">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="154"
              height="154"
              viewBox="0 0 154 154"
            >
              <g fill="none" stroke="#22AE73" strokeWidth="2">
                <circle
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokeDasharray: "480px, 480px",
                    strokeDashoffset: "960px",
                  }}
                />
                <circle
                  id="colored"
                  fill="#22AE73"
                  cx="77"
                  cy="77"
                  r="72"
                  style={{
                    strokeDasharray: "480px, 480px",
                    strokeDashoffset: "960px",
                  }}
                />
                <polyline
                  className="st0"
                  stroke="#fff"
                  strokeWidth="10"
                  points="43.5,77.8 63.7,97.9 112.2,49.4 "
                  style={{
                    strokeDasharray: "100px, 100px",
                    strokeDashoffset: "200px",
                  }}
                />
              </g>
            </svg>
          </div>
        )}
        {status !== "success" && (
          <>
            <hr />

            <div className="clearFix">
              {status !== "sign-up" && (
                <a
                  href="#sign-up"
                  onClick={(e) => {
                    e.preventDefault();
                    setErrorMessages([]);
                    setStatus("sign-up");
                  }}
                  className={status === "sign-in" ? "" : "rightLink"}
                >
                  Sign up for an account
                </a>
              )}
              {status !== "sign-in" && (
                <a
                  href="#sign-in"
                  onClick={(e) => {
                    e.preventDefault();
                    setErrorMessages([]);
                    setStatus("sign-in");
                  }}
                >
                  Return to log in
                </a>
              )}

              {(status === "sign-in" || status === "sign-up") && (
                <a
                  href="#forgot"
                  onClick={(e) => {
                    e.preventDefault();
                    setErrorMessages([]);
                    setStatus("forgot");
                  }}
                  className="rightLink"
                >
                  Forgot password?
                </a>
              )}
            </div>
          </>
        )}
      </div>
      {popup && popup === "privacy" && (
        <div className="privacyContainer">
          <div className="container terms">
            <a
              href="#close"
              className="closeBtn"
              onClick={(e) => {
                e.preventDefault();
                setPopup(false);
              }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                />
              </svg>
            </a>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>Privacy Policy for {appState.siteName}</h1>
            <p>
              At {appState.siteName}, accessible from https://
              {appState.siteName}.org, one of our main priorities is the privacy
              of our visitors. This Privacy Policy document contains types of
              information that is collected and recorded by {appState.siteName}{" "}
              and how we use it.
            </p>
            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in {appState.siteName}. This
              policy is not applicable to any information collected offline or
              via channels other than this website.
            </p>
            <h2>Consent</h2>
            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
            <h2>Information we collect</h2>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>
            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>
            <h2>How we use your information</h2>
            <p>
              We use the information we collect in various ways, including to:
            </p>
            <ul>
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>Understand and analyze how you use our website</li>
              <li>
                Develop new products, services, features, and functionality
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>Send you emails</li>
              <li>Find and prevent fraud</li>
            </ul>
            <h2>Log Files</h2>
            <p>
              {appState.siteName} follows a standard procedure of using log
              files. These files log visitors when they visit websites. All
              hosting companies do this and a part of hosting services'
              analytics. The information collected by log files include internet
              protocol (IP) addresses, browser type, Internet Service Provider
              (ISP), date and time stamp, referring/exit pages, and possibly the
              number of clicks. These are not linked to any information that is
              personally identifiable. The purpose of the information is for
              analyzing trends, administering the site, tracking users' movement
              on the website, and gathering demographic information.
            </p>
            <h2>Cookies and Web Beacons</h2>
            <p>
              Like any other website, {appState.siteName} uses 'cookies'. These
              cookies are used to store information including visitors'
              preferences, and the pages on the website that the visitor
              accessed or visited. The information is used to optimize the
              users' experience by customizing our web page content based on
              visitors' browser type and/or other information.
            </p>
            <h2>Google DoubleClick DART Cookie</h2>
            <p>
              Google is one of a third-party vendor on our site. It also uses
              cookies, known as DART cookies, to serve ads to our site visitors
              based upon their visit to www.website.com and other sites on the
              internet. However, visitors may choose to decline the use of DART
              cookies by visiting the Google ad and content network Privacy
              Policy at the following URL –{" "}
              <a
                rel="nofollow"
                href="https://policies.google.com/technologies/ads"
              >
                https://policies.google.com/technologies/ads
              </a>
            </p>
            <h2>Advertising Partners Privacy Policies</h2>
            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of {appState.siteName}.
            </p>
            <p>
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on{" "}
              {appState.siteName}, which are sent directly to users' browser.
              They automatically receive your IP address when this occurs. These
              technologies are used to measure the effectiveness of their
              advertising campaigns and/or to personalize the advertising
              content that you see on websites that you visit.
            </p>
            <p>
              Note that {appState.siteName} has no access to or control over
              these cookies that are used by third-party advertisers.
            </p>
            <h2>Third Party Privacy Policies</h2>
            <p>
              {appState.siteName}'s Privacy Policy does not apply to other
              advertisers or websites. Thus, we are advising you to consult the
              respective Privacy Policies of these third-party ad servers for
              more detailed information. It may include their practices and
              instructions about how to opt-out of certain options.{" "}
            </p>
            <p>
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers'
              respective websites.
            </p>
            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
            <p>
              Under the CCPA, among other rights, California consumers have the
              right to:
            </p>
            <p>
              Request that a business that collects a consumer's personal data
              disclose the categories and specific pieces of personal data that
              a business has collected about consumers.
            </p>
            <p>
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </p>
            <p>
              Request that a business that sells a consumer's personal data, not
              sell the consumer's personal data.
            </p>
            <p>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>
            <h2>GDPR Data Protection Rights</h2>
            <p>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>
            <p>
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </p>
            <p>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </p>
            <p>
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </p>
            <p>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </p>
            <p>
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </p>
            <p>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </p>
            <p>
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>
            <h2>Children's Information</h2>
            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>
            <p>
              {appState.siteName} does not knowingly collect any Personal
              Identifiable Information from children under the age of 13. If you
              think that your child provided this kind of information on our
              website, we strongly encourage you to contact us immediately and
              we will do our best efforts to promptly remove such information
              from our records.
            </p>
          </div>
        </div>
      )}
      {popup && popup === "terms" && (
        <div className="privacyContainer">
          <div className="container terms">
            <a
              href="#close"
              className="closeBtn"
              onClick={(e) => {
                e.preventDefault();
                setPopup(false);
              }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                />
              </svg>
            </a>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>Terms and Conditions</h1>
            <p>Welcome to {appState.siteName}!</p>
            <p>
              These terms and conditions outline the rules and regulations for
              the use of {appState.siteName}'s Website, located at https://
              {appState.siteName}.org.
            </p>
            <p>
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use {appState.siteName}
              if you do not agree to take all of the terms and conditions stated
              on this page.
            </p>
            <p>
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Company’s terms and conditions. "The
              Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client’s needs in respect of
              provision of the Company’s stated services, in accordance with and
              subject to, prevailing law of Netherlands. Any use of the above
              terminology or other words in the singular, plural, capitalization
              and/or he/she or they, are taken as interchangeable and therefore
              as referring to same.
            </p>
            <h3>
              <strong>Cookies</strong>
            </h3>
            <p>
              We employ the use of cookies. By accessing {appState.siteName},
              you agreed to use cookies in agreement with the
              {appState.siteName}'s Privacy Policy.{" "}
            </p>
            <p>
              Most interactive websites use cookies to let us retrieve the
              user’s details for each visit. Cookies are used by our website to
              enable the functionality of certain areas to make it easier for
              people visiting our website. Some of our affiliate/advertising
              partners may also use cookies.
            </p>
            <h3>
              <strong>License</strong>
            </h3>
            <p>
              Unless otherwise stated, {appState.siteName} and/or its licensors
              own the intellectual property rights for all material on{" "}
              {appState.siteName}. All intellectual property rights are
              reserved. You may access this from {appState.siteName} for your
              own personal use subjected to restrictions set in these terms and
              conditions.
            </p>
            <p>You must not:</p>
            <ul>
              <li>Republish material from {appState.siteName}</li>
              <li>
                Sell, rent or sub-license material from {appState.siteName}
              </li>
              <li>
                Reproduce, duplicate or copy material from {appState.siteName}
              </li>
              <li>Redistribute content from {appState.siteName}</li>
            </ul>
            <p>
              Parts of this website offer an opportunity for users to post and
              exchange opinions and information in certain areas of the website.{" "}
              {appState.siteName} does not filter, edit, publish or review
              Comments prior to their presence on the website. Comments do not
              reflect the views and opinions of {appState.siteName}
              ,its agents and/or affiliates. Comments reflect the views and
              opinions of the person who post their views and opinions. To the
              extent permitted by applicable laws, {appState.siteName} shall not
              be liable for the Comments or for any liability, damages or
              expenses caused and/or suffered as a result of any use of and/or
              posting of and/or appearance of the Comments on this website.
            </p>
            <p>
              {appState.siteName} reserves the right to monitor all Comments and
              to remove any Comments which can be considered inappropriate,
              offensive or causes breach of these Terms and Conditions.
            </p>
            <p>You warrant and represent that:</p>
            <ul>
              <li>
                You are entitled to post the Comments on our website and have
                all necessary licenses and consents to do so;
              </li>
              <li>
                The Comments do not invade any intellectual property right,
                including without limitation copyright, patent or trademark of
                any third party;
              </li>
              <li>
                The Comments do not contain any defamatory, libelous, offensive,
                indecent or otherwise unlawful material which is an invasion of
                privacy
              </li>
              <li>
                The Comments will not be used to solicit or promote business or
                custom or present commercial activities or unlawful activity.
              </li>
            </ul>
            <p>
              You hereby grant {appState.siteName} a non-exclusive license to
              use, reproduce, edit and authorize others to use, reproduce and
              edit any of your Comments in any and all forms, formats or media.
            </p>
            <h3>
              <strong>Hyperlinking to our Content</strong>
            </h3>
            <p>
              The following organizations may link to our Website without prior
              written approval:
            </p>
            <ul>
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>
                Online directory distributors may link to our Website in the
                same manner as they hyperlink to the Websites of other listed
                businesses; and
              </li>
              <li>
                System wide Accredited Businesses except soliciting non-profit
                organizations, charity shopping malls, and charity fundraising
                groups which may not hyperlink to our Web site.
              </li>
            </ul>
            <p>
              These organizations may link to our home page, to publications or
              to other Website information so long as the link: (a) is not in
              any way deceptive; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products
              and/or services; and (c) fits within the context of the linking
              party’s site.
            </p>
            <p>
              We may consider and approve other link requests from the following
              types of organizations:
            </p>
            <ul>
              <li>
                commonly-known consumer and/or business information sources;
              </li>
              <li>dot.com community sites;</li>
              <li>associations or other groups representing charities;</li>
              <li>online directory distributors;</li>
              <li>internet portals;</li>
              <li>accounting, law and consulting firms; and</li>
              <li>educational institutions and trade associations.</li>
            </ul>
            <p>
              We will approve link requests from these organizations if we
              decide that: (a) the link would not make us look unfavorably to
              ourselves or to our accredited businesses; (b) the organization
              does not have any negative records with us; (c) the benefit to us
              from the visibility of the hyperlink compensates the absence of{" "}
              {appState.siteName}; and (d) the link is in the context of general
              resource information.
            </p>
            <p>
              These organizations may link to our home page so long as the link:
              (a) is not in any way deceptive; (b) does not falsely imply
              sponsorship, endorsement or approval of the linking party and its
              products or services; and (c) fits within the context of the
              linking party’s site.
            </p>
            <p>
              If you are one of the organizations listed in paragraph 2 above
              and are interested in linking to our website, you must inform us
              by sending an e-mail to {appState.siteName}. Please include your
              name, your organization name, contact information as well as the
              URL of your site, a list of any URLs from which you intend to link
              to our Website, and a list of the URLs on our site to which you
              would like to link. Wait 2-3 weeks for a response.
            </p>
            <p>
              Approved organizations may hyperlink to our Website as follows:
            </p>
            <ul>
              <li>By use of our corporate name; or</li>
              <li>
                By use of the uniform resource locator being linked to; or
              </li>
              <li>
                By use of any other description of our Website being linked to
                that makes sense within the context and format of content on the
                linking party’s site.
              </li>
            </ul>
            <p>
              No use of {appState.siteName}'s logo or other artwork will be
              allowed for linking absent a trademark license agreement.
            </p>
            <h3>
              <strong>iFrames</strong>
            </h3>
            <p>
              Without prior approval and written permission, you may not create
              frames around our Webpages that alter in any way the visual
              presentation or appearance of our Website.
            </p>
            <h3>
              <strong>Content Liability</strong>
            </h3>
            <p>
              We shall not be hold responsible for any content that appears on
              your Website. You agree to protect and defend us against all
              claims that is rising on your Website. No link(s) should appear on
              any Website that may be interpreted as libelous, obscene or
              criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights.
            </p>
            <h3>
              <strong>Your Privacy</strong>
            </h3>
            <p>Please read Privacy Policy</p>
            <h3>
              <strong>Reservation of Rights</strong>
            </h3>
            <p>
              We reserve the right to request that you remove all links or any
              particular link to our Website. You approve to immediately remove
              all links to our Website upon request. We also reserve the right
              to amen these terms and conditions and it’s linking policy at any
              time. By continuously linking to our Website, you agree to be
              bound to and follow these linking terms and conditions.
            </p>
            <h3>
              <strong>Removal of links from our website</strong>
            </h3>
            <p>
              If you find any link on our Website that is offensive for any
              reason, you are free to contact and inform us any moment. We will
              consider requests to remove links but we are not obligated to or
              so or to respond to you directly.
            </p>
            <p>
              We do not ensure that the information on this website is correct,
              we do not warrant its completeness or accuracy; nor do we promise
              to ensure that the website remains available or that the material
              on the website is kept up to date.
            </p>
            <h3>
              <strong>Disclaimer</strong>
            </h3>
            <p>
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website. Nothing in this disclaimer will:
            </p>
            <ul>
              <li>
                limit or exclude our or your liability for death or personal
                injury;
              </li>
              <li>
                limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;
              </li>
              <li>
                limit any of our or your liabilities in any way that is not
                permitted under applicable law; or
              </li>
              <li>
                exclude any of our or your liabilities that may not be excluded
                under applicable law.
              </li>
            </ul>
            <p>
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer: (a) are subject to the preceding
              paragraph; and (b) govern all liabilities arising under the
              disclaimer, including liabilities arising in contract, in tort and
              for breach of statutory duty.
            </p>
            <p>
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignIn;
