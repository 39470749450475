import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { convertToCEFR, getJWT, removeJWT } from "../utils";
import { useContext, useEffect, useState } from "react";
import axios from "../axios";
import { CSVLink } from "react-csv";
import { AppContext } from "../context";

const Account = () => {
  const history = useHistory();
  const selected = "hover:underline text-primary";
  const normal = "hover:underline hover:text-primary";
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [scoreFormat, setScoreFormat] = useState("CEFR"); // 'IELTS' or 'CEFR'
  const [appState, appDispatch] = useContext(AppContext);
  const [newUsername, setNewUsername] = useState(appState?.user?.name || "");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (appState?.user?.name) {
      setNewUsername(appState.user.name);
    }
  }, [appState?.user?.name]);

  const location = useLocation();

  const formatScore = (score) => {
    if (scoreFormat === "CEFR") {
      return convertToCEFR(score);
    }
    return score.toFixed(0);
  };

  useEffect(() => {
    const loadData = async () => {
      const data = await axios.get(`/api/diplom/tests?${new Date().getTime()}`);
      setData(data.data);
    };
    loadData();
  }, [location.pathname]);

  const page = location.pathname;

  const isPremium = appState?.user?.isPremiumDictionary;

  const handleSaveUsername = async () => {
    if (!newUsername.trim()) return;

    setIsSaving(true);
    try {
      await axios.post("/api/diplom/new-user-name", { name: newUsername });
    } catch (error) {
      console.error("Failed to update username:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const ieltsTestLength = data?.filter((item) => item.testId).length;
  const quickTestLength = data?.filter((item) => !item.testId).length;
  return (
    <div className="">
      <style jsx>{`
        table {
          border-spacing: 1px;
          border-collapse: separate;
          border: 1px solid #9d9d9d;
          border-radius: 5px;
          box-shadow: 1px 2px 20px 10px rgb(177 174 174 / 14%);
        }

        table thead th {
          padding: 10px;
          background-color: #000 !important;
          color: #fff;
          font-weight: normal;
          border-radius: 3px;
        }

        table td,
        table th {
          padding: 8px;
        }

        table tr:nth-child(odd) td {
          background-color: #e0e0e0;
        }

        table tr:nth-child(even) td,
        table tr th {
          background-color: #eee;
        }

        .startLinksWrapper {
          padding: 20px;
        }

        .startLinksWrapper a {
          display: block;
          margin: 20px auto;
        }

        table.myTable {
          width: 400px !important;
        }

        table.myTable th,
        table.myTable td {
          width: 50%;
        }

        table.myTable tbody td:first-child {
          text-align: left;
        }

        table.myTable tbody td:nth-child(2) {
          text-align: center;
        }

        table.myTable tr:nth-child(even) td,
        table.myTable tr th {
          background-color: #eee;
        }

        @media (max-width: 768px) {
          .hideOnMobile {
            display: none;
          }
        }
      `}</style>
      <section className="bg-white">
        <div className="relative max-w-[1200px] mx-auto">
          <div className="relative flex flex-row justify-between px-8 mt-6 text-black z-2">
            <a
              href="/"
              style={{
                fontSize: "22px",
                fontWeight: "400",
                letterSpacing: "0.3px",
                marginTop: "-7px",
              }}
            >
              Diplom.org
            </a>

            <div className="flex flex-row items-center justify-center gap-8 text-black">
              <a
                href="/tests"
                className={page === "/tests" ? selected : normal}
              >
                Tests
              </a>
              <a
                href="/account"
                className={page === "/account" ? selected : normal}
              >
                Account
              </a>
              {getJWT() && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    removeJWT();
                    window.dispatchEvent(
                      new CustomEvent("LOGOUT", { detail: {} })
                    );
                    window.location.reload();
                  }}
                  href="#log-out"
                  className="hover:underline hover:text-primary"
                >
                  Log Out
                </a>
              )}
            </div>
          </div>
          <div className="px-8 pb-1 text-center">
            <div
              style={{ maxWidth: "800px" }}
              className="relative mx-auto mt-5 text-black"
            >
              {location.pathname === "/tests" &&
              data?.some(
                (item) =>
                  item.listeningScore ||
                  item.readingScore ||
                  (item.writingUuid &&
                    item.writing1GenBand !== null &&
                    item.writing2GenBand !== null) ||
                  (item.speakingUuid && item.speakingGenBand !== null)
              ) ? (
                <div className="flex justify-end mb-4 width=[800px] mt-10">
                  <div className="inline-flex p-[1px] border border-gray-400 rounded-lg">
                    <button
                      onClick={() => setScoreFormat("IELTS")}
                      className={`px-3 py-1 text-sm font-medium rounded-md transition-colors ${
                        scoreFormat === "IELTS"
                          ? "bg-black text-white shadow-sm"
                          : "text-gray-500 hover:text-gray-700"
                      }`}
                    >
                      IELTS Score
                    </button>
                    <button
                      onClick={() => setScoreFormat("CEFR")}
                      className={`px-3 py-1 text-sm font-medium rounded-md transition-colors ${
                        scoreFormat === "CEFR"
                          ? "bg-black text-white shadow-sm"
                          : "text-gray-500 hover:text-gray-700"
                      }`}
                    >
                      CEFR Level
                    </button>
                  </div>
                </div>
              ) : (
                <div style={{ height: "30px" }}></div>
              )}
            </div>
            {location.pathname === "/account" && (
              <h1 className="mx-auto mb-3 text-3xl text-center text-black center">
                User Account
              </h1>
            )}
            <div className="relative inline-block mx-auto mb-5 text-black">
              {location.pathname === "/tests" && (
                <>
                  <h1 className="mx-auto mb-3 text-3xl text-center text-black center">
                    IELTS Tests
                  </h1>
                  <table style={{ maxWidth: "800px" }}>
                    <thead>
                      <tr>
                        <th>Test #</th>
                        <th className="hideOnMobile">Created</th>
                        <th>Listening</th>
                        <th>Reading</th>
                        <th>Writing</th>
                        <th>Speaking</th>
                        <th>Overall Score</th>
                      </tr>
                    </thead>
                    {ieltsTestLength > 0 ? (
                      <tbody>
                        {data
                          .filter((item) => item.testId)
                          .map((item) => {
                            return (
                              <tr>
                                <td>{item.testId}</td>
                                <td align="center" className="hideOnMobile">
                                  {String(
                                    new Date(item.createdAt).toLocaleString()
                                  )}
                                </td>
                                <td align="center">
                                  {item.listeningScore ? (
                                    <a
                                      href={`/replay/listening/${item.listeningUuid}/${item.listeningMimeType}/${item.listeningId}`}
                                      className="text-black underline"
                                    >
                                      {formatScore(
                                        Math.round(item.listeningScore / 10)
                                      )}
                                    </a>
                                  ) : (
                                    <a
                                      href={`/test/listening/${item.testId}`}
                                      className="text-blue-700 underline"
                                    >
                                      Start
                                    </a>
                                  )}
                                </td>
                                <td align="center">
                                  {item.readingScore ? (
                                    <a
                                      href={`/replay/reading/${item.readingUuid}/${item.readingMimeType}/${item.readingId}`}
                                      className="text-black underline"
                                    >
                                      {formatScore(
                                        Math.round(item.readingScore / 10)
                                      )}
                                    </a>
                                  ) : (
                                    <a
                                      href={`/test/reading-general/${item.testId}`}
                                      className="text-blue-700 underline"
                                    >
                                      Start
                                    </a>
                                  )}
                                </td>
                                <td align="center">
                                  {item.writingUuid ? (
                                    item.writing1GenBand === null ||
                                    item.writing2GenBand === null ? (
                                      <span>Calculating...</span>
                                    ) : (
                                      <a
                                        href={`/replay/writing/${item.writingUuid}/${item.writingMimeType}/${item.writingId}/${item.writing1Url}/${item.writing2Url}`}
                                        className="text-black underline"
                                      >
                                        {formatScore(
                                          item.writing1GenBand / 3 +
                                            (2 * item.writing2GenBand) / 3
                                        )}
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href={`/test-writing/${item.testId}`}
                                      className="text-blue-700 underline"
                                    >
                                      Start
                                    </a>
                                  )}
                                </td>
                                <td align="center">
                                  {item.speakingUuid ? (
                                    item.speakingGenBand === null ? (
                                      <span>Calculating...</span>
                                    ) : (
                                      <a
                                        href={`/replay/speaking/${item.speakingUuid}/${item.speakingMimeType}/${item.speakingId}/${item.speakingBundleId}`}
                                        className="text-black underline"
                                      >
                                        {formatScore(item.speakingGenBand)}
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href={`/test-speaking/${item.testId}`}
                                      className="text-blue-700 underline"
                                    >
                                      Start
                                    </a>
                                  )}
                                </td>
                                <td align="center">
                                  {item.totalBand ? (
                                    <a
                                      href={item.diplomUrl}
                                      className="text-black underline"
                                    >
                                      {formatScore(item.totalBand)}
                                    </a>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={7} align="center">
                            <br />
                            No Tests
                            <br />
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>

                  {ieltsTestLength < 4 && (
                    <button
                      onClick={async () => {
                        if (!loading) {
                          setLoading(true);
                          await axios.post("/api/diplom/add-ielts-test", {
                            testId: ieltsTestLength + 1,
                          });

                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        }
                      }}
                      className="px-4 py-2 my-5 text-white bg-blue-600 rounded"
                    >
                      {loading ? "Creating..." : "Add IELTS Test"}
                    </button>
                  )}

                  <h1 className="mx-auto mb-3 text-3xl text-center text-black mt-14 center">
                    Quick Tests
                  </h1>
                  <table style={{ margin: "0 auto", maxWidth: "650px" }}>
                    <thead>
                      <tr>
                        <th>Test #</th>
                        <th className="hideOnMobile">Created</th>
                        <th>Writing</th>
                        <th>Speaking</th>
                        <th>Overall Score</th>
                      </tr>
                    </thead>
                    {quickTestLength > 0 ? (
                      <tbody>
                        {data
                          .filter((item) => !item.testId)
                          .map((item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td align="center" className="hideOnMobile">
                                  {String(
                                    new Date(item.createdAt).toLocaleString()
                                  )}
                                </td>

                                <td align="center">
                                  {item.writingUuid ? (
                                    item.writing1GenBand === null ? (
                                      <span>Calculating...</span>
                                    ) : (
                                      <a
                                        href={`/replay/writing/${item.writingUuid}/${item.writingMimeType}/${item.writingId}/${item.writing1Url}`}
                                        className="text-black underline"
                                      >
                                        {formatScore(item.writing1GenBand)}
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href={`/quick-test-writing/${item.myTestId}`}
                                      className="text-blue-700 underline"
                                    >
                                      Start
                                    </a>
                                  )}
                                </td>
                                <td align="center">
                                  {item.speakingUuid ? (
                                    item.speakingGenBand === null ? (
                                      <span>Calculating...</span>
                                    ) : (
                                      <a
                                        href={`/replay/speaking/${item.speakingUuid}/${item.speakingMimeType}/${item.speakingId}/${item.speakingBundleId}`}
                                        className="text-black underline"
                                      >
                                        {formatScore(item.speakingGenBand)}
                                      </a>
                                    )
                                  ) : (
                                    <a
                                      href={`/quick-test-speaking/${item.myTestId}`}
                                      className="text-blue-700 underline"
                                    >
                                      Start
                                    </a>
                                  )}
                                </td>
                                <td align="center">
                                  {item.totalBand ? (
                                    <a
                                      href={item.diplomUrl}
                                      className="text-black underline"
                                    >
                                      {formatScore(item.totalBand)}
                                    </a>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={7} align="center">
                            <br />
                            No Tests
                            <br />
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                  <button
                    onClick={async () => {
                      if (!loading) {
                        setLoading(true);
                        await axios.post("/api/diplom/add-quick-test");

                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      }
                    }}
                    className="px-4 py-2 my-5 text-white bg-blue-600 rounded"
                  >
                    {loading ? "Creating..." : "Add Quick Test"}
                  </button>
                </>
              )}

              {location.pathname === "/account" && appState.user && (
                <>
                  <table width={"100%"} className="myTable">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td align="center" style={{ padding: "0" }}>
                          <input
                            type="text"
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                            className="w-48 px-2 py-1 text-center border rounded"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Subscription</td>
                        <td align="center">Premium</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={handleSaveUsername}
                      disabled={isSaving || !newUsername.trim()}
                      className={`px-4 py-2 text-sm rounded ${
                        isSaving || !newUsername.trim()
                          ? "bg-gray-300 cursor-not-allowed"
                          : "bg-blue-600 hover:bg-blue-700 text-white"
                      }`}
                    >
                      {isSaving ? "Saving..." : "Save"}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Account;
