import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../axios";
import RRWebPlayer from "rrweb-player";
import { getReplayConsolePlugin } from "@rrweb/rrweb-plugin-console-replay";
import "rrweb-player/dist/style.css";
import Draggable from "react-draggable";
import { geFolderById, getPathById } from "../utils";

const ReplayPlayer = () => {
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const { type, uuid, mimeType, id, param5, param6 } = useParams();
  const [camWidgetPosition, setCamWidgetPosition] = useState({ x: 0, y: 0 });
  const [rrwebHistory, setRrwebHistory] = useState([]);
  const lastTimeRef = useRef(0); // Track last time to detect jumps
  const TIME_JUMP_THRESHOLD = 500; // Minimum jump (ms) to detect a click

  useEffect(() => {
    let isMounted = true;

    (async () => {
      try {
        // Clean up previous player instance if it exists
        if (playerRef.current) {
          playerRef.current.destroy();
          playerRef.current = null;
        }

        // Clear the container
        if (containerRef.current) {
          containerRef.current.innerHTML = "";
        }

        // // Fetch all RRWeb history for display
        // const sessionsRes = await axios.get(
        //   `/api/track/rrweb-sessions/user/${replayId}`
        // );
        // if (sessionsRes.data && isMounted) {
        //   setRrwebHistory(sessionsRes.data);
        // }

        // // Construct the correct URL depending on environment
        // const baseUrl =
        //   process.env.NODE_ENV === "development"
        //     ? "http://localhost:5001/rrweb-logs"
        //     : "/rrweb-logs";

        const logsUrl = `/diplom-answers/${geFolderById(id)}/${uuid}.json`;
        const rrwebRes = await axios.get(logsUrl);

        if (rrwebRes.data && isMounted) {
          const events = rrwebRes.data;

          // Override console
          overrideConsoleLogs();

          // Initialize RRWeb player
          if (containerRef.current && !playerRef.current) {
            // Assuming eventBatch is your array of stringified events from recording
            const parsedEvents = events.map((eventString) =>
              JSON.parse(eventString)
            );

            // Instantiate RRWebPlayer with debug logging
            playerRef.current = new RRWebPlayer({
              target: containerRef.current,
              props: {
                events: parsedEvents,
                showController: true,
                autoPlay: false,
                skipInactive: false,
                plugins: [
                  getReplayConsolePlugin({
                    level: ["info", "log", "warn", "error"],
                  }),
                ],
              },
            });

            const goBlur = () => {
              const iframe = containerRef.current?.querySelector("iframe");
              if (iframe && iframe.contentDocument) {
                const doc = iframe.contentDocument;
                for (let i = 1; i <= 9; i++) {
                  doc.querySelectorAll(`#section${i} img`).forEach((img) => {
                    img.style.setProperty("filter", "blur(10px)", "important");
                  });
                }
                console.log(
                  "Applied blur to images inside section1 to section9"
                );
              }
            };

            const player = playerRef.current;
            let isPlaying = false;
            let isBlurred = false;

            // Sync video play/pause state
            player.addEventListener("ui-update-player-state", (event) => {
              const state = event.payload; // "playing" | "paused" | "finished"
              console.log("STATE ->", state);
              isPlaying = state === "playing";
              if (videoRef.current) {
                switch (state) {
                  case "playing":
                    videoRef.current.play();
                    goBlur();
                    break;
                  case "paused":
                    videoRef.current.pause();
                    break;
                  case "finished":
                    videoRef.current.pause();
                    videoRef.current.currentTime = 0;
                    break;
                  default:
                    console.log("Unknown state:", state);
                    break;
                }
              }
            });

            // Sync video position only on significant jumps (e.g., progress bar clicks)
            player.addEventListener("ui-update-current-time", (e) => {
              const newTime = e.payload; // Assuming payload is time in ms
              const timeDifference = Math.abs(newTime - lastTimeRef.current);

              // Sync video only if it's a significant jump or player is paused
              if (
                videoRef.current &&
                (timeDifference > TIME_JUMP_THRESHOLD || !isPlaying)
              ) {
                goBlur();
                const videoTime = newTime / 1000; // Convert ms to seconds
                videoRef.current.currentTime = videoTime;
                console.log(
                  "Video synced to:",
                  videoTime,
                  "seconds (progress bar click or manual jump)"
                );
              } /* else {
                console.log("Ignoring playback update:", newTime);
              } */

              lastTimeRef.current = newTime; // Update last known time
            });
          }
        }
      } catch (e) {
        const wrapper = document.getElementById("playerWrapper");
        if (wrapper) {
          wrapper.style.display = "none";
        }
        console.error("Error initializing player:", e);
      }
    })();

    // Cleanup on unmount or replayId change
    return () => {
      isMounted = false;
      if (playerRef.current) {
        playerRef.current.destroy();
        playerRef.current = null;
      }
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, [uuid, mimeType, id]);

  const handleDrag = (e, data) => {
    //if (!this.state.isMinimized) {
    setCamWidgetPosition({ x: data.x, y: data.y });
    // }
  };

  return (
    <div>
      {/* {param5 && <a href={`/report/speaking/${param5}`}>Speaking Report</a>} */}
      <Draggable
        position={camWidgetPosition}
        onDrag={handleDrag}
        bounds={{
          top: 0,
          left: 0,
          right: window.innerWidth - 340,
          bottom: window.innerHeight - 265,
        }}
      >
        <div className="max-w-lg px-6 pt-1 pb-5 mx-auto rounded-lg myCamRecorder">
          <style jsx>{`
            .myCamRecorder {
              background: rgba(255, 255, 255, 0.99);
              max-width: 340px;
              width: 100%;
              position: fixed;
              z-index: 10000;
              cursor: move;
              border: 1px solid rgb(102 94 94);
            }
            .myCamRecorder select,
            .myCamRecorder select:active,
            .myCamRecorder select:focus {
              margin-left: 0;
              margin-right: 0;
              background: #000;
            }

            .myCamRecorder img {
              pointer-events: none;
              -webkit-user-drag: none;
              user-select: none;
            }
          `}</style>
          <h2 className="flex justify-center mb-1 text-xs font-semibold text-center">
            Camera Recording
          </h2>
          <video
            style={{
              border: "1px solid rgb(102 94 94)",
              borderRadius: "3px",
            }}
            ref={videoRef}
            src={`/diplom-answers/${geFolderById(id)}/${uuid}.${mimeType}`}
            // controls
          />
        </div>
      </Draggable>
      <style jsx>{`
        .video-box {
          width: 250px;
          height: 250px;
          background: rgba(47, 46, 46, 0.9);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          cursor: move;
          position: fixed;
          z-index: 100000;
          transition: background 0.3s ease-in-out, width 0.3s ease-in-out,
            height 0.3s ease-in-out, transform 0.3s ease-in-out;
        }

        .video-box.minimized {
          width: 40px;
          height: 40px;
          padding: 0;
          border-radius: 50%;
          cursor: pointer;
          transform: translate(
            calc(100vw - 60px),
            calc(100vh - 60px)
          ) !important;
        }

        .video-box:not(.minimized):active {
          transition: none;
        }
        .blink182 {
          animation: blinker 0.8s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
          color: red;
          margin: 5px;
        }
        .blink183 {
          animation: blinker 0.8s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
          color: red;
        }
        @keyframes blinker {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }

        .rr-progress__handler.svelte-19ke1iv.svelte-19ke1iv,
        .rr-controller__btns.svelte-19ke1iv button.active.svelte-19ke1iv,
        .switch.svelte-9brlez
          input[type="checkbox"].svelte-9brlez:checked
          + label.svelte-9brlez:before {
          background-color: #000 !important;
        }
        .rr-progress__step.svelte-19ke1iv.svelte-19ke1iv {
          background-color: #373737 !important;
        }
        .switch.svelte-9brlez label.svelte-9brlez.svelte-9brlez:before {
          background: rgb(114 114 116 / 50%);
        }
      `}</style>
      <div
        id="playerWrapper"
        style={{ textAlign: "center" }}
        className="clearfix"
      >
        <div ref={containerRef}></div>

        {/* Inline styles */}
        <style jsx>{`
          #consoleLog {
            background-color: #eee;
            width: 400px;
            height: 653px;
            margin: 3px auto 0;
            text-align: left;
            padding: 10px;
            box-sizing: border-box;
            overflow-y: auto;
            font-family: monospace;
            font-size: 12px;
            line-height: 16px;
            float: right;
          }

          /*@media (max-width: 1425px) { */
          .rr-player {
            float: none;
            margin: 0 auto;
          }
          #consoleLog {
            width: 1024px;
            float: none;
            margin-top: 20px;
            position: relative;
          }
          #close-console-log {
            display: none;
          }
          #consoleLog {
            display: none;
          }
          /* ******************** */
          /* } */

          .clearfix::after {
            content: " ";
            visibility: hidden;
            display: block;
            height: 0;
            clear: both;
          }
        `}</style>

        <div
          id="close-console-log"
          style={{
            position: "absolute",
            top: "3px",
            right: "3px",
            backgroundColor: "#fff",
            cursor: "pointer",
            padding: "5px 10px",
            fontWeight: "bold",
          }}
          onClick={() => {
            const consoleDiv = document.getElementById("consoleLog");
            if (consoleDiv) consoleDiv.innerHTML = "";
          }}
        >
          ✕
        </div>

        <div id="consoleLog"></div>
      </div>
    </div>
  );
};

// Override console methods
function overrideConsoleLogs() {
  const consoleDiv = document.getElementById("consoleLog");
  if (!consoleDiv) return;

  const methods = ["log", "info", "warn", "error"];

  methods.forEach((method) => {
    const original = console[method];
    console[method] = function (...args) {
      original.apply(console, args);
      const message = args
        .map((arg) =>
          typeof arg === "object" ? JSON.stringify(arg, null, 2) : arg
        )
        .join(" ");
      const logEntry = document.createElement("div");

      if (message.includes("System Information")) {
        logEntry.style.color = "red";
        logEntry.style.fontWeight = "bold";
        logEntry.innerHTML = `[${method.toUpperCase()}] ${message.replace(
          /\n/g,
          "<br>"
        )}`;
      } else {
        logEntry.style.color =
          method === "error"
            ? "red"
            : method === "warn"
            ? "orange"
            : method === "info"
            ? "blue"
            : "black";
        logEntry.textContent = `[${method.toUpperCase()}] ${message}`;
      }

      consoleDiv.appendChild(logEntry);
      consoleDiv.scrollTop = consoleDiv.scrollHeight;
    };
  });
}

export default ReplayPlayer;
