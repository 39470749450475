import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as BrowserRouterBase,
  HashRouter as HashRouterBase,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import App from "./App";
import { AppProvider } from "../context";
import Login from "./Login";
import { getJWT } from "../utils";
import Pricing from "./Pricing";
import Account from "./Account";
import ReplayPlayer from "./ReplayPlayer";
import HashPage from "./HashPage";
import Diplom from "./Diplom";
import StandardTestResult from "./tests/StandardTestResult";

// import TestApp from "./tests/TestApp"
const TestApp = lazy(() => import("./tests/TestApp"));
const TestWriting = lazy(() => import("./tests/TestWriting"));
const TestSpeaking = lazy(() => import("./tests/TestSpeaking"));

function PrivateRoute({ component: Component, ...rest }) {
  const isLoggedIn = getJWT();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: props.location.pathname },
            }}
          />
        )
      }
    />
  );
}

function HashHandler({ children }) {
  const location = useLocation();
  const [hash, setHash] = useState(location.hash.slice(1));

  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash.slice(1));
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  return (
    <>{hash && hash.startsWith("A") ? <Diplom hash={hash} /> : children}</>
  );
}

function AppRouter() {
  const SelectedRouter = !window.cordova ? BrowserRouterBase : HashRouterBase;

  return (
    <AppProvider>
      <SelectedRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <HashHandler>
            <Switch>
              <Route path="/sign-in" component={Login} />
              <Route path="/pricing" render={() => <App focus="pricing" />} />
              <Route
                path="/test-result/:type/:testId"
                component={StandardTestResult}
              />
              <Route
                path="/replay/:type/:uuid/:mimeType/:id/:param5?/:param6?"
                component={ReplayPlayer}
              />

              <PrivateRoute path="/account" component={Account} />
              <PrivateRoute path="/tests" component={Account} />
              <PrivateRoute path="/test/" component={TestRouting} />
              <PrivateRoute
                path="/test-writing/:testId"
                component={TestWriting}
              />
              <PrivateRoute
                path="/test-speaking/:testId"
                component={TestSpeaking}
              />
              <PrivateRoute
                path="/quick-test-writing/:testId"
                component={TestWriting}
                format="quick"
              />
              <PrivateRoute
                path="/quick-test-speaking/:testId"
                component={TestSpeaking}
                format="quick"
              />

              <PrivateRoute path="/start/:type" component={Account} />
              <Route path="/" component={App} />
            </Switch>
          </HashHandler>
        </Suspense>
      </SelectedRouter>
    </AppProvider>
  );
}

function TestRouting() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route
          path="/test/:testType/:testId"
          render={(props) => <TestApp {...props} />}
        />
        <Route path="/test/">
          <div>Coming soon</div>
        </Route>
      </Switch>
    </Suspense>
  );
}

export default AppRouter;
