import { useContext, useState, useEffect } from "react";

const Features = ({ isGetStarted }) => {
  const [currentFeatureTab, setCurrentFeatureTab] = useState(
    isGetStarted ? 4 : 1
  );
  return (
    <section
      id="features"
      className="relative pt-[150px] min-h-[600px] md:pt-0 md:min-h-screen bg-white md:snap-start"
    >
      <div className="relative md:min-h-screen max-w-[800px] flex items-center mx-auto">
        <div className="flex flex-col">
          <div class="tabs-container boxShadow">
            <div class="tabs-block">
              <div id="tabs-section" class="tabs">
                <ul class="tab-head">
                  <li>
                    <a
                      class="tab-link"
                      href="#tab-2"
                      className={
                        currentFeatureTab === 1 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(1);
                      }}
                    >
                      <svg
                        viewBox="0 0 22 22"
                        fill="currentColor"
                        width="23px"
                        height="23px"
                        className="inline-block mx-auto"
                      >
                        <path
                          d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <span class="tab-label">Verified Assessment</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-1"
                      className={
                        currentFeatureTab === 2 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(2);
                      }}
                    >
                      <svg
                        fill="currentColor"
                        width="23px"
                        height="23px"
                        className="inline-block mx-auto"
                        focusable="false"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"></path>
                      </svg>{" "}
                      <span class="tab-label">Screening Interview</span>
                    </a>
                  </li>

                  <li>
                    <a
                      class="tab-link"
                      href="#tab-3"
                      className={
                        currentFeatureTab === 3 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(3);
                      }}
                    >
                      <svg
                        viewBox="0 0 72 72"
                        height="20px"
                        width="20px"
                        className="inline-block mx-auto"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <path
                            d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z"
                            fill="#FFF"
                          ></path>
                        </g>
                      </svg>

                      <span class="tab-label">LinkedIn Integration</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#tab-4"
                      class={
                        currentFeatureTab === 4 ? `tab-link active` : "tab-link"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentFeatureTab(4);
                      }}
                    >
                      <svg
                        fill="currentColor"
                        height="25px"
                        width="25px"
                        className="inline-block mx-auto"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                      >
                        <path d="M17.41 6.59 15 5.5l2.41-1.09L18.5 2l1.09 2.41L22 5.5l-2.41 1.09L18.5 9zm3.87 6.13L20.5 11l-.78 1.72-1.72.78 1.72.78.78 1.72.78-1.72L23 13.5zm-5.04 1.65 1.94 1.47-2.5 4.33-2.24-.94c-.2.13-.42.26-.64.37l-.3 2.4h-5l-.3-2.41c-.22-.11-.43-.23-.64-.37l-2.24.94-2.5-4.33 1.94-1.47c-.01-.11-.01-.24-.01-.36s0-.25.01-.37l-1.94-1.47 2.5-4.33 2.24.94c.2-.13.42-.26.64-.37L7.5 6h5l.3 2.41c.22.11.43.23.64.37l2.24-.94 2.5 4.33-1.94 1.47c.01.12.01.24.01.37s0 .24-.01.36M13 14c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3"></path>
                      </svg>

                      <span class="tab-label">Different Formats</span>
                    </a>
                  </li>
                  {/* <li>
                <a href="#tab-5" class="tab-link">
                  {" "}
                  <span class="material-icons tab-icon">toll</span>{" "}
                  <span class="tab-label">Blush</span>
                </a>
              </li> */}
                </ul>

                <section
                  id="tab-2"
                  className={
                    currentFeatureTab === 1
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">
                    Verified Assessment
                  </h2>
                  <p>
                    Each assessment includes video verification, ensuring that
                    every user’s English proficiency level is accurately
                    evaluated and authenticated.
                  </p>
                  <p>
                    Speaking and pronunciation assessments are recorded on
                    video, providing clear proof of language skills. Writing
                    assessments are checked in real time, preventing any
                    external assistance.
                  </p>
                  <p>
                    This verification process guarantees fairness, making it
                    easy to trust the results and track progress reliably.
                  </p>
                </section>

                <section
                  id="tab-1"
                  className={
                    currentFeatureTab === 2
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">
                    Screening Interview
                  </h2>
                  <p>
                    How many times have you heard a recruiter say, "Let's go for
                    a quick call" for a screening interview? This feature saves
                    time by letting candidates answer screening questions via
                    video.
                  </p>
                  <p>
                    Instead of unnecessary meetings, recruiters can review these
                    videos to assess candidates' basic information and English
                    skills. You can send a link to your prerecorded interview,
                    letting recruiters see and hear your responses. This
                    streamlines hiring, ensuring both candidates and recruiters
                    focus on meaningful interactions over repetitive screenings.
                  </p>
                </section>

                <section
                  id="tab-3"
                  className={
                    currentFeatureTab === 3
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">
                    LinkedIn Integration
                  </h2>
                  <p>
                    Our certificate is seamlessly integrated with the LinkedIn
                    platform, making it easy for candidates to showcase their
                    verified English proficiency.
                  </p>
                  <p>
                    Adding the certification to LinkedIn helps complete the
                    picture of a candidate’s skills, including their screening
                    interview.
                  </p>
                  <p>
                    This integration saves time for both recruiters and
                    candidates by streamlining the hiring process and enhancing
                    professional credibility.
                  </p>
                </section>

                <section
                  id="tab-4"
                  className={
                    currentFeatureTab === 4
                      ? `tab-body entry-content active active-content`
                      : "tab-body entry-content"
                  }
                >
                  <h2 className="mb-2 text-3xl text-center">
                    Different Formats
                  </h2>

                  <p>
                    Our system supports <b>Swift Evaluation</b>, allowing users
                    to complete a 30-minute assessment. This includes a
                    20-minute real-time writing task for email responses and a
                    10-minute speaking Q&A, providing a quick and efficient way
                    to assess English proficiency.
                  </p>
                  <p>
                    For a more in-depth assessment, the{" "}
                    <b>Complete Evaluation</b> follows the full IELTS/TOEFL
                    format, featuring a 1-hour listening section, 1-hour
                    reading, 1-hour writing, and a 15-minute speaking
                    assessment.
                  </p>
                </section>
              </div>
            </div>
          </div>
          <div className="relative flex flex-row justify-between px-2 transition duration-500 mt-14 opacity-40 hover:opacity-100">
            <div className="absolute font-bold text-black -translate-x-1/2 -top-6 left-1/2">
              Paid Alternatives
            </div>
            <div class="w-[17%] flex justify-center pl-4 pr-4">
              <img src="/static/logo/ielts.png" class="w-full object-contain" />
            </div>
            <div class="w-[17%] px-[3%] flex justify-center">
              <img src="/static/logo/gmat.png" class="w-full object-contain" />
            </div>

            <div class="w-[17%] flex justify-center">
              <img src="/static/logo/toefl.svg" class="w-full object-contain" />
            </div>

            <div class="w-[17%] flex justify-center">
              <img src="/static/logo/toeic.png" class="w-full object-contain" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
