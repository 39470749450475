import { useHistory, useRef } from "react-router-dom";
import Pricing from "./Pricing";
import { lazy, useEffect, useState } from "react";
import Features from "./Features";
import { getJWT, removeJWT } from "../utils";
import VideoRecorder from "./VideoRecorder";
import Draggable from "react-draggable";

function App({ focus }) {
  const history = useHistory();
  const scrollToPricing = () => {
    document.getElementById("pricing").scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFeatures = () => {
    document.getElementById("features").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (focus === "features") {
      scrollToFeatures();
    } else if (focus === "pricing") {
      scrollToPricing();
    }
  }, [focus]);

  return (
    <div className="relative top-0 overflow-y-scroll md:h-screen md:snap-y md:snap-mandatory">
      {/* <section className="text-black bg-white md:h-screen snap-start">
        <VideoRecorder />
      </section> 
      
       Free Online English Certification
      */}

      <div style={{ display: "none" }}>
        <h1>English test with free certification online</h1>
      </div>
      <section className="bg-white md:min-h-screen snap-start">
        <div className="relative md:h-screen max-w-[1200px] mx-auto">
          <div className="absolute left-0 right-0 z-20 flex flex-row items-start justify-center px-8 text-black sm:justify-between top-6">
            <a
              href="/"
              style={{
                fontSize: "22px",
                fontWeight: "400",
                letterSpacing: "0.3px",
                marginTop: "-7px",
              }}
            >
              Diplom.org
            </a>

            <div className="flex-row items-center justify-center hidden gap-8 text-black sm:flex">
              <a
                href="#features"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToFeatures();
                }}
                className="hover:underline hover:text-primary"
              >
                Features
              </a>
              <a
                href="#pricing"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToPricing();
                }}
                className="hover:underline hover:text-primary"
              >
                Pricing
              </a>
              <a href="/account" className="hover:underline hover:text-primary">
                Account
              </a>
              {getJWT() && (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    removeJWT();
                    window.dispatchEvent(
                      new CustomEvent("LOGOUT", { detail: {} })
                    );
                    window.location.reload();
                  }}
                  href="#log-out"
                  className="hover:underline hover:text-primary"
                >
                  Log Out
                </a>
              )}
            </div>
          </div>

          <div class="z-2 relative md:h-[100%] min-h-[600px] flex flex-col md:flex-row px-8">
            <div className="h-[55%] md:h-[100%] md:w-[40%] sm:flex justify-center ">
              <div class="h-full pt-20 md:pt-0 md:h-auto flex justify-center items-center relative">
                {/* <video
                  className="w-full h-auto boxShadow"
                  playsInline="1"
                  autoPlay="1"
                  loop="1"
                >
                  <source src="/static/landing_video.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
                <a href="https://diplom.org/#AMYW2J">
                  <img
                    src="/static/diplom.png"
                    alt="Screen"
                    className=""
                    style={{
                      border: "1px solid #000",
                    }}
                  />
                </a>
                {/* <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 144 144"
                  width="110"
                  height="110"
                  className="playSvg"
                  onClick={(e) => {
                    e.preventDefault();
                    window
                      .open(
                        "https://www.youtube.com/watch?v=wS2FzRff0U8",
                        "_blank"
                      )
                      .focus();
                  }}
                >
                  <style type="text/css">{`
	.st0z{fill:#ff0000;}
	.st1z{fill:#FFFFFF;}
  .playSvg{
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -32px;
    opacity: 0;
    transition: all 150ms ease 0s;
    width: 65px;
     opacity: 1;
     cursor: pointer;
    }
        @media (max-width: 700px) {
         .playSvg{
             margin-top: -22px;
         }
        }

`}</style>
                  <path
                    d="M129.6,101.7c-1.2,6.3-6.3,11.1-12.6,12c-9.9,1.5-26.4,3.3-45,3.3c-18.3,0-34.8-1.8-45-3.3 c-6.3-0.9-11.4-5.7-12.6-12C13.2,94.8,12,84.6,12,72s1.2-22.8,2.4-29.7c1.2-6.3,6.3-11.1,12.6-12c9.9-1.5,26.4-3.3,45-3.3 s34.8,1.8,45,3.3c6.3,0.9,11.4,5.7,12.6,12c1.2,6.9,2.7,17.1,2.7,29.7C132,84.6,130.8,94.8,129.6,101.7z"
                    className="jsx-c79b1519f2bfba94 st0z"
                  ></path>
                  <path
                    d="M60,93V51l36,21L60,93z"
                    className="jsx-c79b1519f2bfba94 st1z"
                  ></path>
                </svg> */}
              </div>
            </div>
            <div className="md:w-[7%]"></div>

            <div className="md:h-[100%] md:w-[50%] flex flex-col text-black justify-center p-5">
              <div className="mb-3 text-4xl text-center">
                Diploma in English Proficiency
              </div>
              <div>
                Verify your English proficiency with video-recorded assessments
                for speaking and real-time writing checks. Save time with a
                screening interview and seamlessly add your certification to
                LinkedIn. Choose between a swift 30-minute evaluation or a full
                IELTS/TOEFL-style test.
              </div>
              <div className="flex flex-col items-center gap-10 mt-10">
                <a
                  class="cursor-pointer text-center track-btn relative mx-auto md:mx-0 flex justify-center w-72 items-center z-0 h-14 rounded-xl px-6 text-neutral-50 animate-scale opacity-100 transition duration-500 bg-secondary after:bg-secondary md:bg-primary md:after:bg-primary"
                  href="/tests"
                >
                  {/* <img
                    class="w-8 h-8 mr-3"
                    alt="Google Chrome Store"
                    src="/static/chrome-store.svg"
                  /> */}
                  <svg
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    width={24}
                    height={24}
                    style={{ margin: "-5px 7px -5px -5px" }}
                  >
                    <path
                      fill="currentColor"
                      d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
                    ></path>
                  </svg>
                  Start English Assessment
                </a>
                <div class="flex flex-row items-center gap-2">
                  <div class="hidden sm:flex">
                    <img
                      class="avatar"
                      src="/static/avatars/1.jpg"
                      lazy="loaded"
                      alt="avatar"
                    />
                    <img
                      class="avatar"
                      src="/static/avatars/2.jpg"
                      lazy="loaded"
                      alt="avatar"
                    />
                    <img
                      class="avatar"
                      src="/static/avatars/3.jpg"
                      lazy="loaded"
                      alt="avatar"
                    />
                    <img
                      class="avatar"
                      src="/static/avatars/4.jpg"
                      lazy="loaded"
                      alt="avatar"
                    />
                    <div class="flex justify-center items-center font-medium avatar text-xs text-white bg-primary">
                      2K+
                    </div>
                  </div>
                  <div class="flex flex-col space-y-1 text-black md:text-black">
                    <div class="flex sm:justify-start justify-center items-center space-x-2">
                      <div class="flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          data-slot="icon"
                          class="w-5 h-5 text-yellow-400"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <div className="text-black">
                        <strong>4.9</strong> / 5
                      </div>
                    </div>
                    <div className="text-black">
                      Trusted by <strong>2,518+</strong> users
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <h2>Dictionarying Features</h2> */}

      <Features isGetStarted={focus === "features"} />
      <Pricing />
    </div>
  );
}

export default App;
