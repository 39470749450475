// src/components/VideoRecorder.tsx
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

export interface VideoRecorderRef {
  startRecording: () => void;
  stopRecording: () => void;
}

interface VideoRecorderProps {
  showControls?: boolean;
  onRecordingStopped?: (data: { thumbnail: string; video: Blob }) => void;
  minimizeWidget: () => void;
}

const VideoRecorder = forwardRef<VideoRecorderRef, VideoRecorderProps>(
  ({ showControls = true, onRecordingStopped, minimizeWidget }, ref) => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
    const [error, setError] = useState("");
    const [isPlayback, setIsPlayback] = useState(false);
    const [duration, setDuration] = useState(0);
    const [isPreview, setIsPreview] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [thumbnail, setThumbnail] = useState<string | null>(null);
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioInputDevices, setAudioInputDevices] = useState<
      MediaDeviceInfo[]
    >([]);
    const [audioOutputDevices, setAudioOutputDevices] = useState<
      MediaDeviceInfo[]
    >([]);
    const [selectedVideoDevice, setSelectedVideoDevice] = useState("");
    const [selectedAudioInput, setSelectedAudioInput] = useState("");
    const [selectedAudioOutput, setSelectedAudioOutput] = useState("");

    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const streamRef = useRef<MediaStream | null>(null);
    const chunksRef = useRef<Blob[]>([]);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    useImperativeHandle(ref, () => ({
      startRecording,
      stopRecording,
    }));

    const formatDuration = (seconds: number) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`;
    };

    const startTimer = () => {
      setDuration(0);
      timerRef.current = setInterval(() => {
        setDuration((prev) => prev + 1);
      }, 1000);
    };

    const stopTimer = () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };

    const enumerateDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInputs = devices.filter(
          (device) => device.kind === "videoinput"
        );
        const audioInputs = devices.filter(
          (device) => device.kind === "audioinput"
        );
        const audioOutputs = devices.filter(
          (device) => device.kind === "audiooutput"
        );

        setVideoDevices(videoInputs);
        setAudioInputDevices(audioInputs);
        setAudioOutputDevices(audioOutputs);

        if (!selectedVideoDevice && videoInputs.length > 0) {
          setSelectedVideoDevice(videoInputs[0].deviceId);
        }
        if (!selectedAudioInput && audioInputs.length > 0) {
          setSelectedAudioInput(audioInputs[0].deviceId);
        }
        if (!selectedAudioOutput && audioOutputs.length > 0) {
          setSelectedAudioOutput(audioOutputs[0].deviceId);
        }
      } catch (err) {
        setError("Failed to enumerate media devices.");
        console.error("Error enumerating devices:", err);
      }
    };

    const generateThumbnail = useCallback((videoBlob: Blob) => {
      if (!videoBlob || videoBlob.size === 0) {
        console.error("Invalid or empty video blob for thumbnail generation");
        setError("Recording is empty; cannot generate thumbnail.");
        return;
      }

      const video = document.createElement("video");
      const url = URL.createObjectURL(videoBlob);
      video.src = url;

      const cleanupVideo = () => {
        URL.revokeObjectURL(url);
        video.remove();
      };

      video.onerror = () => {
        console.error("Error loading video for thumbnail");
        setError("Failed to load video for thumbnail.");
        cleanupVideo();
      };

      video.onloadedmetadata = () => {
        try {
          let thumbnailTime = 0;
          if (video.duration > 5) {
            thumbnailTime = 5;
          } else if (video.duration > 1) {
            thumbnailTime = 1;
          }
          video.currentTime = Math.min(thumbnailTime, video.duration - 0.1);
        } catch (err) {
          console.error("Error seeking video:", err);
          cleanupVideo();
        }
      };

      video.onseeked = () => {
        try {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            const thumbnailUrl = canvas.toDataURL("image/jpeg");
            setThumbnail(thumbnailUrl);
          }
          cleanupVideo();
        } catch (err) {
          console.error("Error generating thumbnail:", err);
          setError("Failed to generate thumbnail.");
          cleanupVideo();
        }
      };

      video.load();
    }, []);

    const startPreview = useCallback(async () => {
      try {
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
        }

        const constraints = {
          video: {
            ...(selectedVideoDevice && {
              deviceId: { exact: selectedVideoDevice },
            }),
            width: { exact: 400 },
            height: { exact: 300 },
            resizeMode: "crop-and-scale",
          },
          audio: selectedAudioInput
            ? { deviceId: { exact: selectedAudioInput } }
            : true,
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        streamRef.current = stream;

        const videoTrack = stream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();
        console.log(`Actual resolution: ${settings.width}x${settings.height}`);

        if (settings.width !== 400 || settings.height !== 300) {
          console.log("Resolution not matched, consider manual downscaling.");
        }

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.muted = true;
        }
        setIsPreview(true);
        setError("");
        await enumerateDevices();
      } catch (err) {
        setError(
          "Failed to access camera. Please ensure permissions are granted."
        );
        console.error("Error accessing media devices:", err);
      }
    }, [selectedVideoDevice]);

    const resetToPreview = useCallback(() => {
      setRecordedBlob(null);
      setThumbnail(null);
      setIsPlayback(false);
      setCurrentTime(0);
      setTotalDuration(0);
      chunksRef.current = [];
      startPreview();
    }, [startPreview]);

    const startRecording = useCallback(async () => {
      if (isRecording) return;
      if (!streamRef.current) {
        await startPreview();
        if (!streamRef.current) return;
      }

      // Set up canvas to enforce 400x300
      const video = document.createElement("video");
      video.srcObject = streamRef.current;
      video.muted = true; // Avoid audio feedback
      await video.play(); // Wait for video to start playing

      const canvas = document.createElement("canvas");
      canvas.width = 400;
      canvas.height = 300;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        setError("Canvas context not supported.");
        return;
      }

      // Draw the video to the canvas at 400x300, continuously
      let animationFrameId: number | null = null;
      const drawToCanvas = () => {
        ctx.drawImage(video, 0, 0, 400, 300);
        animationFrameId = requestAnimationFrame(drawToCanvas); // Keep looping
      };
      drawToCanvas();

      // Capture the canvas stream
      const resizedStream = canvas.captureStream(30); // 30 fps, adjust if needed

      // Add audio tracks from the original stream
      const audioTracks = streamRef.current.getAudioTracks();
      audioTracks.forEach((track) => resizedStream.addTrack(track));

      let chosenMimeType = "";
      if (MediaRecorder.isTypeSupported("video/webm;codecs=vp9")) {
        chosenMimeType = "video/webm;codecs=vp9";
      } else if (MediaRecorder.isTypeSupported("video/webm;codecs=vp8")) {
        chosenMimeType = "video/webm;codecs=vp8";
      } else if (MediaRecorder.isTypeSupported("video/webm")) {
        chosenMimeType = "video/webm";
      } else if (MediaRecorder.isTypeSupported("video/mp4;codecs=h264")) {
        chosenMimeType = "video/mp4;codecs=h264";
      } else {
        setError(
          "Your browser does not support any known video recording format."
        );
        return;
      }

      try {
        mediaRecorder.current = new MediaRecorder(resizedStream, {
          mimeType: chosenMimeType,
        });

        chunksRef.current = [];
        setThumbnail(null);
        setRecordedBlob(null);
        setIsPreview(false);

        mediaRecorder.current.ondataavailable = (event) => {
          if (event.data && event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        };

        mediaRecorder.current.onstop = () => {
          const blob = new Blob(chunksRef.current, { type: chosenMimeType });
          setRecordedBlob(blob);
          generateThumbnail(blob);

          // Stop the canvas drawing loop
          if (animationFrameId !== null) {
            cancelAnimationFrame(animationFrameId);
            animationFrameId = null;
          }

          // Verify recorded resolution
          const checkVideo = document.createElement("video");
          checkVideo.src = URL.createObjectURL(blob);
          checkVideo.onloadedmetadata = () => {
            console.log(
              `Recorded resolution: ${checkVideo.videoWidth}x${checkVideo.videoHeight}`
            );
            URL.revokeObjectURL(checkVideo.src);
            checkVideo.remove();
          };
        };

        mediaRecorder.current.start(1000); // Record in 1-second chunks
        setIsRecording(true);
        setIsPlayback(false);
        startTimer();
      } catch (err) {
        setError("Failed to start video recording.");
        console.error("Error starting video recording:", err);

        // Clean up on error
        if (animationFrameId !== null) {
          cancelAnimationFrame(animationFrameId);
        }
      }
    }, [generateThumbnail, startPreview, isRecording]);

    const stopRecording = useCallback(() => {
      if (!isRecording || !mediaRecorder.current) return;
      mediaRecorder.current.stop();
      stopTimer();
      setIsRecording(false);

      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }

      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setIsPreview(false);
    }, [isRecording]);

    const playRecording = useCallback(() => {
      if (!recordedBlob || !videoRef.current) return;
      try {
        const url = URL.createObjectURL(recordedBlob);
        videoRef.current.srcObject = null;
        videoRef.current.src = url;
        videoRef.current.muted = false;
        videoRef.current.onended = () => setIsPlayback(false);
        setIsPlayback(true);
        setIsPreview(false);
        videoRef.current.play().catch((err) => {
          console.error("Error playing video:", err);
          setError("Failed to play video.");
        });
      } catch (err) {
        console.error("Error setting up video playback:", err);
        setError("Failed to setup video playback.");
      }
    }, [recordedBlob]);

    const downloadRecording = useCallback(() => {
      if (recordedBlob) {
        const url = URL.createObjectURL(recordedBlob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "video_recording.webm";
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }, 100);
      }
    }, [recordedBlob]);

    useEffect(() => {
      if (!isRecording && recordedBlob && thumbnail && onRecordingStopped) {
        console.log("xxx onRecordingStopped ==>", thumbnail, recordedBlob);
        onRecordingStopped({
          thumbnail: thumbnail as string,
          video: recordedBlob,
        });
      }
    }, [isRecording, recordedBlob, thumbnail, onRecordingStopped]);

    useEffect(() => {
      if (isPlayback && videoRef.current) {
        const video = videoRef.current;
        const timeUpdateHandler = () => {
          setCurrentTime(video.currentTime);
          setTotalDuration(video.duration);
        };
        video.addEventListener("timeupdate", timeUpdateHandler);
        video.addEventListener("loadedmetadata", timeUpdateHandler);
        return () => {
          video.removeEventListener("timeupdate", timeUpdateHandler);
          video.removeEventListener("loadedmetadata", timeUpdateHandler);
        };
      }
    }, [isPlayback]);

    useEffect(() => {
      enumerateDevices();
      startPreview();
      return () => {
        if (streamRef.current) {
          streamRef.current.getTracks().forEach((track) => track.stop());
        }
        stopTimer();
      };
    }, [startPreview]);

    return (
      <div className="max-w-lg px-6 py-5 mx-auto rounded-lg myCamRecorder">
        <style>{`
        .myCamRecorder{
           background: rgba(47, 46, 46, 0.99);
        }
        .myCamRecorder select,
        .myCamRecorder select:active,
        .myCamRecorder select:focus{
           margin-left: 0;
           margin-right: 0;
           background: #000;
        }

        .myCamRecorder img {
          pointer-events: none;
          -webkit-user-drag: none;
          user-select: none;
        }
        `}</style>
        <h2 className="flex justify-between mb-5 text-xl font-semibold text-white">
          <span>Video Recorder</span>
          <svg
            width="30"
            height="30"
            viewBox="0 0 1000 1000"
            style={{ borderRadius: "8px", cursor: "pointer" }}
            onClick={() => {
              minimizeWidget();
            }}
          >
            <rect x="0" y="0" width="100%" height="100%" />
            <g
              transform="matrix(-1.1364 0 0 1.1364 500.0145 500.0145)"
              id="391614"
            >
              <g vectorEffect="non-scaling-stroke">
                <g transform="matrix(33.3333 0 0 33.3333 0.0061 -0.0103)">
                  <path
                    style={{
                      stroke: "rgb(255,255,255)",
                      strokeWidth: 1.5,
                      strokeDasharray: "none",
                      strokeLinecap: "round",
                      strokeDashoffset: 0,
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 4,
                      fill: "none",
                      fillRule: "nonzero",
                      opacity: 0.5,
                    }}
                    transform="translate(-12.0002, -11.9997)"
                    d="M 12.9999 21.9994 C 17.055 21.9921 19.1784 21.8926 20.5354 20.5355 C 21.9999 19.0711 21.9999 16.714 21.9999 12 C 21.9999 7.28595 21.9999 4.92893 20.5354 3.46447 C 19.071 2 16.714 2 11.9999 2 C 7.28587 2 4.92884 2 3.46438 3.46447 C 2.10734 4.8215 2.00779 6.94493 2.00049 11"
                    strokeLinecap="round"
                  />
                </g>
                <g transform="matrix(33.3333 0 0 33.3333 83.3332 -83.3335)">
                  <path
                    style={{
                      stroke: "rgb(255,255,255)",
                      strokeWidth: 1.5,
                      strokeDasharray: "none",
                      strokeLinecap: "round",
                      strokeDashoffset: 0,
                      strokeLinejoin: "round",
                      strokeMiterlimit: 4,
                      fill: "none",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    transform="translate(-14.5, -9.5)"
                    d="M 17 7 L 12 12 M 12 12 H 15.75 M 12 12 V 8.25"
                    strokeLinecap="round"
                  />
                </g>
                <g transform="matrix(33.3333 0 0 33.3333 -200.0001 199.9999)">
                  <path
                    style={{
                      stroke: "rgb(255,255,255)",
                      strokeWidth: 1.5,
                      strokeDasharray: "none",
                      strokeLinecap: "butt",
                      strokeDashoffset: 0,
                      strokeLinejoin: "miter",
                      strokeMiterlimit: 4,
                      fill: "none",
                      fillRule: "nonzero",
                      opacity: 1,
                    }}
                    transform="translate(-6, -18)"
                    d="M 2 18 C 2 16.1144 2 15.1716 2.58579 14.5858 C 3.17157 14 4.11438 14 6 14 C 7.88562 14 8.82843 14 9.41421 14.5858 C 10 15.1716 10 16.1144 10 18 C 10 19.8856 10 20.8284 9.41421 21.4142 C 8.82843 22 7.88562 22 6 22 C 4.11438 22 3.17157 22 2.58579 21.4142 C 2 20.8284 2 19.8856 2 18 Z"
                    strokeLinecap="round"
                  />
                </g>
              </g>
            </g>
          </svg>
        </h2>
        {error && (
          <div className="p-3 mb-4 text-red-600 bg-red-100 rounded">
            {error}
          </div>
        )}
        <div className="relative">
          <video
            ref={videoRef}
            style={{
              width: "100%",
              aspectRatio: "16/12",
              backgroundColor: "#f3f4f6",
              borderRadius: "4px",
              marginBottom: "20px",
              display:
                !isPlayback && thumbnail && !isRecording && !isPreview
                  ? "none"
                  : "block",
            }}
            autoPlay
            playsInline
            controls={isPlayback}
          />
          {!isPlayback && thumbnail && !isRecording && !isPreview && (
            <div
              style={{
                position: "relative",
                width: "100%",
                aspectRatio: "16/12",
                marginBottom: "20px",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <img
                src={thumbnail}
                alt="Recording thumbnail"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  borderRadius: "50%",
                  padding: "20px",
                  cursor: "pointer",
                }}
                onClick={playRecording}
              >
                <div
                  style={{
                    width: "0",
                    height: "0",
                    borderTop: "15px solid transparent",
                    borderBottom: "15px solid transparent",
                    borderLeft: "25px solid white",
                    marginLeft: "5px",
                  }}
                />
              </div>
            </div>
          )}
          {(isRecording ||
            isPreview ||
            (isPlayback &&
              typeof totalDuration === "number" &&
              totalDuration !== Infinity &&
              totalDuration > 0)) && (
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                padding: "4px 8px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "white",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            >
              {isRecording && (
                <>
                  <span className="blink183">🔴</span>
                  {` `}
                  {formatDuration(duration)}
                </>
              )}
              {isPreview && "📸 Preview"}
              {isPlayback &&
                `⏱️ ${formatDuration(currentTime)} / ${formatDuration(
                  totalDuration
                )}`}
            </div>
          )}
        </div>

        {isPreview && !isRecording && (
          <div className="mb-4 text-white">
            <div className="mb-2">
              <label className="block mb-1 text-sm font-medium">
                Video Input:
              </label>
              <select
                value={selectedVideoDevice}
                onChange={(e) => {
                  setSelectedVideoDevice(e.target.value);
                  startPreview();
                }}
                className="w-full px-1 py-2 border rounded"
              >
                {videoDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label ||
                      `Camera ${videoDevices.indexOf(device) + 1}`}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-2">
              <label className="block mb-1 text-sm font-medium">
                Audio Input:
              </label>
              <select
                value={selectedAudioInput}
                onChange={(e) => {
                  setSelectedAudioInput(e.target.value);
                  startPreview();
                }}
                className="w-full px-1 py-2 border rounded"
              >
                {audioInputDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label ||
                      `Microphone ${audioInputDevices.indexOf(device) + 1}`}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden mb-2">
              <label className="block mb-1 text-sm font-medium">
                Audio Output:
              </label>
              <select
                value={selectedAudioOutput}
                onChange={(e) => {
                  setSelectedAudioOutput(e.target.value);
                  // Safe runtime check for setSinkId
                  if (videoRef.current && videoRef.current.setSinkId) {
                    videoRef.current.setSinkId(e.target.value);
                  }
                }}
                className="w-full px-1 py-2 border rounded"
              >
                {audioOutputDevices.map((device) => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label ||
                      `Speaker ${audioOutputDevices.indexOf(device) + 1}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        {showControls ? (
          <div className="flex flex-wrap gap-2">
            {!(recordedBlob && !isRecording) && (
              <button
                onClick={isRecording ? stopRecording : startRecording}
                className={`px-4 py-2 text-white rounded ${
                  isRecording ? "bg-red-600" : "bg-blue-600"
                }`}
              >
                {isRecording ? "Stop Video" : "Start Video"}
              </button>
            )}
            {recordedBlob && (
              <button
                onClick={playRecording}
                className="px-4 py-2 text-white bg-blue-600 rounded"
              >
                Play Video
              </button>
            )}
            {recordedBlob && (
              <button
                onClick={downloadRecording}
                className="px-4 py-2 text-blue-600 border border-blue-600 rounded"
              >
                Download Video
              </button>
            )}
            {recordedBlob && !isRecording && (
              <button
                onClick={resetToPreview}
                className="px-4 py-2 text-green-600 border border-green-600 rounded"
              >
                Reset to Preview
              </button>
            )}
          </div>
        ) : null}
      </div>
    );
  }
);

export default VideoRecorder;
