function hashCode(str) {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const getJWT = () => {
  return localStorage.getItem("_IELTS69_JWT_");
};

const getDomain = (isStatic) => {
  let baseURL = "https://diplom.org"; // window.cordova

  if (process.env.NODE_ENV === "development") {
    baseURL = isStatic ? "http://localhost:3010" : "http://localhost:3050";
  }
  //baseURL = isStatic ? "http://192.168.1.5:3010" : "http://192.168.1.5:3050";

  return baseURL;
};

const openExternalLink = (url) => {
  window.open(url, "_blank").focus();
};

const passDataToIframe = (name, data) => {
  if (window.frames?.iframe_content) {
    window.frames.iframe_content.postMessage(
      {
        type: name,
        data: data,
      },
      "*"
    );
  }
};

const setJWT = (JWT) => {
  localStorage.setItem("_IELTS69_JWT_", JWT);
};

const removeJWT = () => {
  localStorage.removeItem("_IELTS69_JWT_");
};

const removeVocabularies = () => {
  localStorage.removeItem("_VING_GAME_0_");
  localStorage.removeItem("_VING_GAME_1_");
  localStorage.removeItem("_VING_GAME_2_");
  localStorage.removeItem("_VING_LOC_GAME_0_");
  localStorage.removeItem("_VING_LOC_GAME_1_");
  localStorage.removeItem("_VING_LOC_GAME_2_");
};

function intToRGB(i) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}

function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
}

const timeSince = (date) => {
  const seconds = Math.floor(
    new Date().getTime() / 1000 - new Date(date).getTime() / 1000
  );

  let interval = seconds / 31536000;

  if (interval > 1) {
    const intervalInUnits = Math.floor(interval);
    return `${intervalInUnits} year${intervalInUnits === 1 ? "" : "s"}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const intervalInUnits = Math.floor(interval);
    return `${intervalInUnits} month${intervalInUnits === 1 ? "" : "s"}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const intervalInUnits = Math.floor(interval);
    return `${intervalInUnits} day${intervalInUnits === 1 ? "" : "s"}`;
  }
  interval = seconds / 3600;
  if (interval >= 1) {
    const intervalInUnits = Math.floor(interval);
    return `${intervalInUnits} hour${intervalInUnits === 1 ? "" : "s"}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    const intervalInUnits = Math.floor(interval);
    return `${intervalInUnits} minute${intervalInUnits === 1 ? "" : "s"}`;
  }

  return `${Math.floor(seconds)} seconds`;
};

function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

function onlyUniqueValuesInArray(value, index, array) {
  return array.indexOf(value) === index;
}

function isShittySite() {
  return (
    window.location.hostname === "grammar.english911.com" ||
    window.location.hostname === "checker.essay-zone.com"
  );
}

// A helper that returns Base64 characters and their indices.
const chars = {
  ascii: function () {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  },
  indices: function () {
    if (!this.cache) {
      this.cache = {};
      const ascii = chars.ascii();

      for (let c = 0; c < ascii.length; c++) {
        const chr = ascii[c];
        this.cache[chr] = c;
      }
    }
    return this.cache;
  },
};

/**
 * Binary to ASCII (encode data to Base64)
 * @param {String} data
 * @returns {String}
 */
const encode = (data) => {
  let ascii = chars.ascii(),
    len = data.length - 1,
    i = -1,
    j = 0,
    b64 = "",
    rn = Math.floor(Math.random() * 8) + 1;

  while (i < len) {
    const code =
      (data.charCodeAt(++i) << 16) |
      (data.charCodeAt(++i) << 8) |
      data.charCodeAt(++i);
    b64 +=
      ascii[(code >>> 18) & 63] +
      ascii[(code >>> 12) & 63] +
      ascii[(code >>> 6) & 63] +
      ascii[code & 63];
  }

  const pads = data.length % 3;
  if (pads > 0) {
    b64 = b64.slice(0, pads - 3);
    while (b64.length % 4 !== 0) {
      b64 += "=";
    }
  }
  for (; j < rn; j++) {
    b64 = b64.charAt(b64.length - 3 - j) + b64;
    if (j === rn - 1) b64 = rn + b64;
  }

  return b64;
};

/**
 * ASCII to binary (decode Base64 to original data)
 * @param {String} b64
 * @returns {String}
 */
const decode = (b64e) => {
  let indices = chars.indices(),
    b64 = b64e.slice(b64e[0]).slice(1),
    i = -1,
    data = "",
    pos = b64.indexOf("="),
    padded = pos > -1,
    len = padded ? pos : b64.length;

  while (i < len) {
    const code =
      (indices[b64[++i]] << 18) |
      (indices[b64[++i]] << 12) |
      (indices[b64[++i]] << 6) |
      indices[b64[++i]];
    if (code !== 0) {
      data += String.fromCharCode(
        (code >>> 16) & 255,
        (code >>> 8) & 255,
        code & 255
      );
    }
  }

  if (padded) {
    data = data.slice(0, pos - b64.length);
  }

  return data;
};

const convertUTF16 = (string) => {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
};

const staticServerURL = "https://paperracer.s3.eu-central-1.amazonaws.com"; //"https://www.nikitos.com";
const serverURL = "https://paperracer.com";

let settings = {
  currentEpisode: undefined,
  currentEpisodeNotCompletedStageArr: [],
  trackIdStageNumberArr: [],
  CompletedStagesArr: [],
  isPopupsShowable: false,
  previousPageUrl: "",
};

// function errorLoadImg(el) {
//   if (staticServerURL && $(el).attr('src').search(staticServerURL) >= 0) {
//       $(el).attr('src', $(el).attr('src').replace(staticServerURL, ''));
//   }
// }

const careerLevels = [
  // Level 1 ___Обучение
  305544, 305548, 305550, 459284, 213466, 305556, 214181, 213624, 460490,

  // Level 2 ___Начало
  305569, 305573, 222509, 767296, 673969, 213532, 222525, 223992, 224012,

  // Level 3 ___Горы  --> 405317
  228229, 209119, 197410, 227129, 228375, 228405, 227103, 210057, 227107,

  // 4 Зима
  450543, 450544, 450545, 450546, 450548, 450549, 450550, 450551, 450554,

  // 5 город
  181122, 209980, 209983, 480693, 203452, 228657, 480694, 228594, 227566,

  // 6 море
  480698, 317027, 480700, 480703, 480704, 480706, 480708, 480712, 480714,

  // 7 НЛО  -->
  480722, 480718, 480726, 480720, 480721, 317117, 480724, 480727, 480728,

  // 8 Космос
  212957, 210491, 210497, 210486, 213334, 210492, 210489, 178350, 213341,

  //9 подумай
  480944, 480941, 480924, 480931, 480937, 480939, 480948,
];

const isCareerLevel = (trackId) => {
  return careerLevels.includes(trackId);
};

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const getPathById = (id) => {
  const filesInDir = 1000;
  var koef = Math.floor(id / filesInDir);
  var start = koef * filesInDir;
  var end = (koef + 1) * filesInDir - 1;
  var path = start + "-" + end + "/" + id;
  return path;
};

const geFolderById = (id) => {
  const filesInDir = 1000;
  var koef = Math.floor(id / filesInDir);
  var start = koef * filesInDir;
  var end = (koef + 1) * filesInDir - 1;
  var path = start + "-" + end;
  return path;
};

const getAvatarById = (id) => {
  if (isNumeric(id)) {
    return staticServerURL + "/img/created/avatar/" + getPathById(id) + ".png";
  } else {
    var str = staticServerURL + "/img/avatar/";
    var ava = id == null || id === "" ? "default.png" : id + ".png";
    return str + ava;
  }
};

function isLevelCompleted(level, data) {
  return (
    data.includes(level * 9 + 1) &&
    data.includes(level * 9 + 2) &&
    data.includes(level * 9 + 3) &&
    data.includes(level * 9 + 4) &&
    data.includes(level * 9 + 5) &&
    data.includes(level * 9 + 6) &&
    data.includes(level * 9 + 7) &&
    data.includes(level * 9 + 8) &&
    data.includes(level * 9 + 9)
  );
}

const transliteratedAlphabet =
  "A B V G D E ZH Z I Y K L M N O P R S T U F H C CH SH SCH ' Y ' E YU YA a b v g d e zh z i y k l m n o p r s t u f h c ch sh sch ' y ' e yu ya e e".split(
    " "
  );

const transliterateString = (str) => {
  let result = "";

  //console.log('length=', transliteratedAlphabet.length);
  if (str) {
    for (let i = 0; i < str.length; ++i) {
      const cc = str.charCodeAt(i);
      //console.log('x=', cc, ' - ', str);
      if (cc === 1025) {
        result += "E";
      } else {
        if (cc >= 1040 && cc < 1107) {
          result += transliteratedAlphabet[cc - 1040];
        } else {
          result += str[i];
        }
      }
      //result += (cc==1025 ? 'E' : (cc>=1040 ? transliteratedAlphabet[cc-1040] : str[i]));
    }
  }
  return result;
};

const formatNumber = (num) => {
  if (num >= 1000 && num < 100000) {
    num = num / 1000;
    num = Math.round(num * 10) / 10;
    num = num + "K";
  } else if (num >= 100000 && num < 1000000) {
    num = num / 1000;
    num = Math.round(num);
    num = num + "K";
  } else if (num >= 1000000) {
    num = num / 1000000;
    num = Math.round(num * 10) / 10;
    num = num + "M";
  }
  return num;
};

const defaultCreatedAt = "2020-12-19T12:55:25.152Z";
const defaultSheetId = "1Um-E5XSlLNDWUrM8Dvm5ht2mIgQ6q7t854iy8oMGB-A";

const defaultVocabulary =
  "5dMQN6eyJkYXRhIjp7ImNvZGUiOjAsInN1Y2Nlc3MiOnRydWUsIm1lc3NhZ2UiOiJzdWNjZXNzIiwicmV0VmFsdWUiOlt7ImZyb250IjoiaHVnZSIsImJhY2siOiJiaWcgaCoiLCJoaW50IjpudWxsfSx7ImZyb250IjoiYXNzaXN0IiwiYmFjayI6ImhlbHAgYSoiLCJoaW50IjoiSGUgY2FuIGFzc2lzdCB5b3Ugd2l0aCB5b3VyIHJlcXVlc3QifSx7ImZyb250IjoiYWx0ZXIiLCJiYWNrIjoiY2hhbmdlIGEqIiwiaGludCI6IlRoZSBzaGlwIGlzIGdvaW5nIHRvIGFsdGVyIGl0cyBjb3Vyc2UuIn0seyJmcm9udCI6InJla29uIiwiYmFjayI6InRoaW5rIHIqIiwiaGludCI6IkkgcmVrb24gdGhhdCB5b3Ugc2hvdWxkIGdvLiJ9LHsiZnJvbnQiOiJpbW1lbnNlbHkiLCJiYWNrIjoidmVyeSBpKiIsImhpbnQiOiJCZWNhdXNlIG9mIGltbWVuc2VseSBwb3NpdGl2ZSByZXZpZXdzIn0seyJmcm9udCI6ImZyZXF1ZW50bHkiLCJiYWNrIjoib2Z0ZW4gZioiLCJoaW50IjoiSSBjb21lIHRvIHRoaXMgc2hvcCBmcmVxdWVudGx5In0seyJmcm9udCI6Im5lZ2xlY3QiLCJiYWNrIjoiaWdub3JlIG4qIiwiaGludCI6IlRlZW5hZ2VycyBvZnRlbiBuZWdsZWN0IHRoZWlyIGR1dGllcy4ifSx7ImZyb250IjoiVWtyYWluaWFuIiwiYmFjayI6ItGD0LrRgNCw0ZfQvdGB0YzQutCwIPCfh7rwn4emIiwiaGludCI6IlRoaXMgaXMgVWtyYWluaWFuIHRleHQuINGD0LrRgNCw0ZfQvdGB0YzQutCwINC80L7QstCwLiJ9LHsiZnJvbnQiOiJDaGluZXNlIiwiYmFjayI6IuS4reWbveS6uvCfh6jwn4ezIiwiaGludCI6IlRoaXMgaXMgQ2hpbmVzZSB0ZXh0IOi/meaYr+S4reaWh+aWh+acrC4ifSx7ImZyb250IjoiQXJhYmljIiwiYmFjayI6Itin2YTZhNi62Kkg2KfZhNi52LHYqNmK2KkiLCJoaW50IjoiVGhpcyBpcyB0ZXh0IGluIEFyYWJpYyDZh9iw2Kcg2YbYtSDYqNin2YTZhNi62Kkg2KfZhNi52LHYqNmK2KkifSx7ImZyb250IjoiSGluZGkiLCJiYWNrIjoi4KS54KS/4KSo4KWN4KSm4KWAIOCkreCkvuCkt+CkviDwn4eu8J+HsyIsImhpbnQiOiJUaGlzIGlzIHRleHQgaW4gSGluZGkuIOCkr+CkuSDgpLngpL/gpILgpKbgpYAg4KSu4KWH4KSCIOCkquCkvuCkoCDgpLngpYgifSx7ImZyb250IjoiU3BhbmlzaCIsImJhY2siOiJlc3Bhw7FvbCDwn4eq8J+HuCIsImhpbnQiOiJUaGlzIGlzIHRleHQgaW4gU3BhbmlzaC4gRXN0ZSBlcyBlbCB0ZXh0byBlbiBlc3Bhw7FvbC4ifSx7ImZyb250Ijoi8J+RjeKcivCfkY4iLCJiYWNrIjoi4pyM77iP8J+RjPCfpJ4iLCJoaW50IjoiSGFuZCBzaWduYWxzIPCfkY3inIrwn5GO4pyM77iP8J+RjPCfpJ4ifSx7ImZyb250Ijoi8J+NkfCfjYvwn42TIiwiYmFjayI6IvCfjY7wn42Q8J+NkiIsImhpbnQiOiJGcnVpdCBFbW9qaXMg8J+NjvCfjZDwn42S8J+NkfCfjYvwn42TIn0seyJmcm9udCI6IvCfmIDwn5iN8J+YmyIsImJhY2siOiLwn5iC8J+Yo/CfmIoiLCJoaW50IjoiU21pbGVzIPCfmILwn5iN8J+YivCfmILwn5iN8J+YiiJ9XX19";

const emptyPNG =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXcAAADlCAMAAACfx0cwAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABCUExURQAAAP///////////////////////////////////////////////////////////////////////////////////whf0oYAAAAWdFJOUwBnacQKxS3qpwGGCdwZLEdJ3YgXAof3cbg0AAAApUlEQVR42u3WyxGCQAAFwQVcFkT5KfmnahBW8S7dIcxpSgEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD400OCiEuCiDZqkNDVWYSAb+1ESBjbR4SEc9IgcpLHJkLC/lpESJieGiS82ypCQj+Y+Hv9AIClARdwMdWiAAAAAElFTkSuQmCC";
const convertToCEFR = (score) => {
  if (score >= 8.5) return "C2";
  if (score >= 7.5) return "C1";
  if (score >= 6.5) return "B2";
  if (score >= 5.5) return "B1";
  if (score >= 4.5) return "A2";
  return "A1";
};
export {
  isLevelCompleted,
  formatNumber,
  transliterateString,
  emptyPNG,
  getAvatarById,
  getPathById,
  careerLevels,
  isCareerLevel,
  settings,
  staticServerURL,
  serverURL,
  intToRGB,
  hashCode,
  getContrastYIQ,
  getCookie,
  timeSince,
  getJWT,
  setJWT,
  removeJWT,
  getDomain,
  shuffleArray,
  onlyUniqueValuesInArray,
  isShittySite,
  encode,
  decode,
  convertUTF16,
  passDataToIframe,
  openExternalLink,
  defaultVocabulary,
  defaultCreatedAt,
  defaultSheetId,
  removeVocabularies,
  geFolderById,
  convertToCEFR,
};
