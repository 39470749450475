import React from "react";

const AnswerReport = ({
  cameraRecordingImage,
  screenRecordingLink,
  detailedReportLink,
}) => {
  return (
    <>
      <hr className="w-4/5 mx-auto my-8 border-0 border-t border-[#444]" />
      <div className="flex flex-row items-stretch justify-center">
        <div className="flex flex-col items-center justify-center gap-8 md:flex-row">
          {/* Detailed Report */}
          <div className="flex flex-col items-center">
            <a
              href={detailedReportLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                fill="#003c64"
                height="100px"
                width="100px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 463 463"
                enableBackground="new 0 0 463 463"
              >
                <g>
                  <g>
                    <path d="m359.5,16h-96c-0.089,0-0.176,0.01-0.264,0.013-7.203-9.708-18.746-16.013-31.736-16.013s-24.533,6.305-31.736,16.013c-0.088-0.003-0.175-0.013-0.264-0.013h-96c-26.191,0-47.5,21.309-47.5,47.5v352c0,26.191 21.309,47.5 47.5,47.5h256c26.191,0 47.5-21.309 47.5-47.5v-352c0-26.191-21.309-47.5-47.5-47.5zm-128-1c13.51,0 24.5,10.991 24.5,24.5v8c0,4.142 3.357,7.5 7.5,7.5h32c4.687,0 8.5,3.813 8.5,8.5s-3.813,8.5-8.5,8.5h-128c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5 8.5-8.5h32c4.143,0 7.5-3.358 7.5-7.5v-8c0-13.509 10.99-24.5 24.5-24.5zm-64,72h128c12.958,0 23.5-10.542 23.5-23.5 0-0.168-0.009-0.333-0.013-0.5h40.513c0.275,0 0.5,0.224 0.5,0.5v352c0,0.276-0.225,0.5-0.5,0.5h-256c-0.275,0-0.5-0.224-0.5-0.5v-352c0-0.276 0.225-0.5 0.5-0.5h40.513c-0.004,0.167-0.013,0.332-0.013,0.5 0,12.958 10.542,23.5 23.5,23.5zm224.5,328.5c0,17.92-14.579,32.5-32.5,32.5h-256c-17.921,0-32.5-14.58-32.5-32.5v-352c0-17.92 14.579-32.5 32.5-32.5h89.431c-0.604,2.74-0.931,5.582-0.931,8.5v0.5h-24.5c-7.023,0-13.332,3.101-17.641,8h-46.359c-8.547,0-15.5,6.953-15.5,15.5v352c0,8.547 6.953,15.5 15.5,15.5h256c8.547,0 15.5-6.953 15.5-15.5v-352c0-8.547-6.953-15.5-15.5-15.5h-46.359c-4.31-4.899-10.619-8-17.641-8h-24.5v-0.5c0-2.918-0.328-5.76-0.931-8.5h89.431c17.921,0 32.5,14.58 32.5,32.5v352z" />
                    <path d="m231.5,63c4.143,0 7.5-3.358 7.5-7.5v-16c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v16c0,4.142 3.357,7.5 7.5,7.5z" />
                    <path d="m223.5,175h96c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-96c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5z" />
                    <path d="m138.196,162.197c-2.929,2.929-2.929,7.678 0,10.606l16,16c1.465,1.464 3.385,2.197 5.304,2.197s3.839-0.732 5.304-2.197l32-32c2.929-2.929 2.929-7.678 0-10.606-2.93-2.929-7.678-2.929-10.607,0l-26.697,26.697-10.696-10.697c-2.93-2.929-7.678-2.929-10.608,0z" />
                    <path d="m223.5,255h96c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-96c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5z" />
                    <path d="m186.196,226.197l-26.696,26.697-10.696-10.697c-2.93-2.929-7.678-2.929-10.607,0s-2.929,7.678 0,10.606l16,16c1.465,1.464 3.385,2.197 5.304,2.197s3.839-0.732 5.304-2.197l32-32c2.929-2.929 2.929-7.678 0-10.606-2.931-2.929-7.679-2.929-10.609,0z" />
                    <path d="m223.5,335h96c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-96c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5 7.5,7.5z" />
                    <path d="m186.196,306.197l-26.696,26.697-10.696-10.697c-2.93-2.929-7.678-2.929-10.607,0s-2.929,7.678 0,10.606l16,16c1.465,1.464 3.385,2.197 5.304,2.197s3.839-0.732 5.304-2.197l32-32c2.929-2.929 2.929-7.678 0-10.606-2.931-2.929-7.679-2.929-10.609,0z" />
                  </g>
                </g>
              </svg>
            </a>
            <p className="mt-2 text-sm text-center">Detailed Report</p>
          </div>

          {/* Camera Recording */}
          <div className="flex flex-col items-center">
            <a
              href={screenRecordingLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="h-[100px] w-auto rounded-lg object-cover"
                alt="Camera recording"
                src={cameraRecordingImage}
              />
            </a>
            <p className="mt-2 text-sm text-center">Camera Recording</p>
          </div>

          {/* Screen Recording */}
          <div className="flex flex-col items-center">
            <a
              href={screenRecordingLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="h-[100px] w-auto rounded-lg object-cover"
                alt="Screen recording"
                src="/static/screen-rec.png"
              />
            </a>
            <p className="mt-2 text-sm text-center">Screen Recording</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnswerReport;
