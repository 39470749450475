import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../axios";

import "../diploma.css";

const AnswerTable = ({ answers }) => {
  const firstColumn = answers.slice(0, 20);
  const secondColumn = answers.slice(20);

  return (
    <div className="flex justify-center w-full">
      <table className="w-full border-separate border-spacing-2">
        <thead>
          <tr>
            <td className="px-2 text-base font-bold text-left">Answers 1-20</td>
            <td className="px-2 text-base font-bold text-left">
              Answers 21-40
            </td>
          </tr>
        </thead>
        <tbody>
          {firstColumn.map((answer, index) => (
            <tr key={index}>
              <td
                className={`p-2.5 align-top ${
                  answer.correct
                    ? "bg-chart-green text-white"
                    : "bg-chart-red text-white"
                }`}
                style={{ width: "50%" }}
              >
                <div className="text-base text-left">
                  <p className="font-bold">{index + 1}</p>
                  <p className="mt-1">{answer.answer || "_______"}</p>
                </div>
              </td>
              <td
                className={`p-2.5 align-top ${
                  secondColumn[index]?.correct
                    ? "bg-chart-green text-white"
                    : "bg-chart-red text-white"
                }`}
                style={{ width: "50%" }}
              >
                <div className="text-base text-left">
                  <p className="font-bold">{index + 21}</p>
                  <p className="mt-1">
                    {secondColumn[index]?.answer || "_______"}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const StandardTestResult = () => {
  const { type, testId } = useParams();
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResult = async () => {
      try {
        setLoading(true);

        const response = await axios.get(
          `/api/diplom/standard-test-result/${type}/${testId}`
        );
        setResult(response.data);
      } catch (err) {
        setError(err.message || "Failed to fetch test result");
        console.error("Error fetching test result:", err);
      } finally {
        setLoading(false);
      }
    };

    if (type && testId) {
      fetchResult();
    }
  }, [type, testId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          width="200"
          height="200"
        >
          <g>
            <circle
              stroke-linecap="round"
              fill="none"
              stroke-dasharray="50.26548245743669 50.26548245743669"
              stroke="#d5d5d5"
              stroke-width="8"
              r="32"
              cy="50"
              cx="50"
            >
              <animateTransform
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1s"
                repeatCount="indefinite"
                type="rotate"
                attributeName="transform"
              ></animateTransform>
            </circle>
            <g></g>
          </g>
        </svg>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg text-red-600">Error: {error}</div>
      </div>
    );
  }

  if (!result) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">No result found</div>
      </div>
    );
  }

  return (
    <main className="flex flex-col items-center mb-12 text-center lg:mt-12">
      <div className="flex flex-col items-center w-full max-w-full shadow-paper page_container__7giYo">
        <div className="relative w-full max-w-full bg-background pb-14 lg:pb-16">
          <div className="max-w-2xl px-6 mx-auto mt-8">
            <h2 className="text-xl font-bold">
              {type.toUpperCase()} TEST RESULT
            </h2>
            <div className="flex flex-col m-auto text-left gap-y-6 lg:gap-y-5 mt-11 lg:mt-8">
              <section className="p-6 bg-white rounded-lg lg:p-10">
                <div class="bg-black w-min mx-auto px-2 rounded mb-8 py-0.5">
                  <h1 class="text-lg font-bold text-white whitespace-nowrap">
                    {result.filter((answer) => answer.correct).length}/40
                    Correct answers
                  </h1>
                </div>
                <AnswerTable answers={result} />
              </section>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default StandardTestResult;
